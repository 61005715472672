import { scaleSqrt } from 'd3'
import { capitalize } from 'lodash-es'
import React from 'react'
import { SIFTED_PINK } from '../lib/constants'
import { format, unsnake } from '../lib/format'

interface Props {
  value?: number
  average?: number
  max: number
  min?: number
  formattedValue?: string
  formattedAverage?: string
  label: string
  className?: string
  dark?: boolean
  showAverage?: boolean
}

const MAX_DIAMETER = 60

export const BigBubble = ({
  value,
  min = 0,
  max,
  average = 0,
  formattedValue = String(value),
  formattedAverage = String(average),
  showAverage = false,
  label,
  className = '',
  dark = false,
}: Props) => {
  const radiusScale = scaleSqrt()
    .domain([min, max])
    .range([0, MAX_DIAMETER / 2])
  return (
    <div className={`${className} space-y-1`} style={{ width: MAX_DIAMETER }}>
      <svg
        width={MAX_DIAMETER}
        height={MAX_DIAMETER}
        viewBox={`0 0 ${MAX_DIAMETER} ${MAX_DIAMETER}`}
      >
        <g transform={`translate(${MAX_DIAMETER / 2} ${MAX_DIAMETER / 2})`}>
          {value && <circle r={radiusScale(value)} fill={SIFTED_PINK} />}
          <circle
            r={radiusScale(average)}
            fill="none"
            stroke={dark ? SIFTED_PINK : '#2C2664'}
            strokeWidth="2"
            strokeDasharray="1 4"
            strokeLinecap="round"
          />

          {showAverage && (
            <text
              fontSize={14}
              fontWeight="bold"
              textAnchor="middle"
              dominantBaseline="middle"
              fill={dark ? 'white' : 'black'}
            >
              {formattedAverage}
            </text>
          )}
        </g>
      </svg>
      <div className="flex flex-col items-center text-center text-sm">
        <div className="font-bold">{formattedValue}</div>
        <div className="text-gray-sft">{format(label, capitalize, unsnake)}</div>
      </div>
    </div>
  )
}
