import React, { FunctionComponent, SVGProps } from 'react'
import { Card } from '../ui/Card'
import { Sources } from '../ui/Sources'

export const Title: React.FC<{ className?: string }> = ({ className = '', children }) => (
  <div className={`${className} font-bold text-4xl`}>{children}</div>
)
export const SubTitle: React.FC<{ className?: string }> = ({ className = '', children }) => (
  <div className={`${className} font-bold text-2xl`}>{children}</div>
)
export const Pragraph: React.FC<{ className?: string }> = ({ children, className = '' }) => (
  <div className={`${className}`}>{children}</div>
)

interface AboutCardProps {
  title: string
  paragraph: string
  sources: { label: string; href: string }[]
  background: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }>
  className?: string
  /** Scale background on mobile to avoid title and background overlapping */
  scaleBackgroundMobile?: boolean
}
export const AboutCard = ({
  title,
  paragraph,
  sources,
  background,
  className = '',
  scaleBackgroundMobile,
}: AboutCardProps) => {
  const Background = background
  return (
    <Card
      className={`${className} p-7 lg:w-aboutpage-card relative z-0 md:h-about-card h-auto overflow-hidden w-full`}
    >
      <div className="flex flex-col h-full justify-between">
        <SubTitle className="w-72">{title}</SubTitle>

        {/* Responsive spacer: height = 80% of width */}
        <div className="md:hidden" style={{ marginBottom: '80%' }} />

        <div>
          <Pragraph className="mb-5">{paragraph}</Pragraph>
          <Sources>
            {sources.map((source, i) => (
              <Sources.Item key={i} {...source} />
            ))}
          </Sources>
        </div>
        <Background
          className={`absolute -z-10 h-full w-full md:top-0 md:left-0 ${
            scaleBackgroundMobile
              ? 'transform md:transform-none scale-125 top-16 left-10'
              : 'top-5 left-0'
          } `}
          preserveAspectRatio="xMaxYMin meet"
        />
      </div>
    </Card>
  )
}
