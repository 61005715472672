import React from 'react'
import { chunk, uniq } from 'lodash-es'
import { Region } from '../lib/constants'
import {
  TalentSalaryAverage,
  TalentSalaryByRegion,
  TalentSalaryMax,
  TalentSalaryMin,
} from '../lib/data'
import { formatPoundSteling, unsnake } from '../lib/format'
import { BigBubble } from './BigBubble'

interface Props {
  activeRegion: Region
}

const TalentSalary = Object.values(TalentSalaryByRegion)
const TechRoles = uniq(TalentSalary.flatMap((d) => d.data.map((datum) => datum.key)))

export const TalentAndEmploymentSalary = ({ activeRegion }: Props) => {
  const { data } = TalentSalaryByRegion[activeRegion]

  const bubbles = TechRoles.map((key) => {
    const datum = data.find((d) => d.key === key)

    return {
      value: datum?.value,
      formattedValue: datum ? formatPoundSteling(datum.value) : 'No data',
      label: unsnake(key),
      average: TalentSalaryAverage[key],
      max: TalentSalaryMax[key],
      min: Math.max(0, TalentSalaryMin[key] - 10_000),
    }
  })

  const grouped = chunk(bubbles, 3)
  return (
    <div className="space-y-2">
      {grouped.map((group, i) => (
        <div key={i} className="flex flex-wrap justify-between">
          {group.map((datum) => (
            <BigBubble key={datum.label} {...datum} />
          ))}
        </div>
      ))}
    </div>
  )
}
