import React, { ComponentProps } from 'react'
import { FooterSifted } from './FooterSifted'
import { LogoMicrosoft } from './LogoMicrosoft'

const Link = (p: ComponentProps<'a'>) => (
  <a {...p} className={`${p.className ?? ''} hover:underline block`} target="_blank">
    {p.children}
  </a>
)
const Column = (p: { className?: string; title: string; children?: React.ReactNode }) => (
  <div className={p.className}>
    <div className="space-y-2" style={{ maxWidth: 150 }}>
      <div className="font-bold">{p.title}</div>
      <div>{p.children}</div>
    </div>
  </div>
)

const FooterSponsor = () => (
  <div className="bg-white lg:p-16 md:p-14 p-11">
    <div className=" grid md:grid-cols-3 grid-cols-2 gap-12">
      <Column title="In partnership with" className="col-span-2 md:col-span-1">
        <LogoMicrosoft className="w-40" />
      </Column>

      <Column title="Data partners">
        <Link href="https://dealroom.co">Dealroom</Link>
        <Link href="https://technation.io">Tech Nation</Link>
        <Link href="https://www.extend.vc">Extend Ventures</Link>
      </Column>

      <Column title="Designed and developed by">
        <Link
          href="https://www.accurat.it/
"
        >
          Accurat
        </Link>
      </Column>
    </div>
  </div>
)

export const Footer = () => (
  <>
    <FooterSponsor />
    <FooterSifted />
  </>
)
