import React from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../state'
import { CardTooltip } from '../ui/CardTooltip'
import { LegendInvestment } from './LegendInvestment'
import { LegendTexture } from './LegendTexture'
import { format, unsnake } from '../lib/format'
import { lowerCase } from 'lodash-es'

const LagendLayout = (p: { title: string; children?: React.ReactNode }) => (
  <div className="flex items-center">
    <div className="text-sm font-medium" style={{ maxWidth: 100 }}>
      {p.title}
    </div>
    <div className="flex-auto">
      <div className="flex">
        <div className="m-auto">{p.children}</div>
      </div>
    </div>
  </div>
)

export const TooltipLegend = observer(() => {
  const { isLarge } = useMst().device
  const { hoveredRegion, activeRegion, activeSectors, activeTexture, sectorColors } = useMst().map

  if (isLarge) return null
  if (activeRegion) return null
  if (hoveredRegion) return null

  return (
    <div className={`absolute ${isLarge ? 'top-0 right-0' : 'right-0 left-0 bottom-0'} p-3`}>
      <div className="w-full m-auto max-w-mobile-card">
        <CardTooltip className="w-full" title="United Kingdom">
          <div className="space-y-2">
            {activeTexture && (
              <LagendLayout title={`Number of ${format(activeTexture, lowerCase, unsnake)}`}>
                <LegendTexture texture={activeTexture} />
              </LagendLayout>
            )}

            {activeTexture && activeSectors.length > 0 && <div className="w-full border-t" />}

            {activeSectors.length > 0 && (
              <LagendLayout title="Emerging tech sectors">
                <div className="flex flex-wrap -mx-2 -my-1">
                  {activeSectors.map((sector) => (
                    <div className="flex items-center space-x-1 mx-2 my-1" key={sector}>
                      <div
                        className="w-4 h-4 rounded-full"
                        style={{ backgroundColor: sectorColors[sector] }}
                      />
                      <div className="text-sm">{unsnake(sector)}</div>
                    </div>
                  ))}
                </div>
              </LagendLayout>
            )}

            {!activeTexture && !activeSectors.length && (
              <LagendLayout title="Investment in tech companies">
                <LegendInvestment
                  width={85}
                  minValue={'£50m'}
                  maxValue={'+£1000m'}
                  minDiameter={10}
                  maxDiameter={20}
                />
              </LagendLayout>
            )}
          </div>
        </CardTooltip>
      </div>
    </div>
  )
})
