import React from 'react'
import { ReactComponent as Arrow } from '../assets/svg/arrow-right-no-circle.svg'
import { SIFTED_PINK } from '../lib/constants'
import { Source } from '../lib/types'

export const Link = (p: { sources: Source; big?: boolean }) => (
  <div
    className={p.big ? 'font-medium text-lg' : 'font-bold text-sm'}
    style={{ color: p.big ? 'black' : SIFTED_PINK }}
  >
    <a
      className="hover:underline flex items-center space-x-3"
      href={p.sources.href}
      target="_blank"
    >
      <div>{p.sources.label}</div>
      <Arrow width={9} height={9} />
    </a>
  </div>
)
