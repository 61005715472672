import { types as t } from 'mobx-state-tree'
import { BREAKPOINT } from '../lib/constants'

const { LG } = BREAKPOINT

export const DeviceModel = t
  .model('DeviceModel', {
    width: window.innerWidth,
    height: window.innerHeight,
  })
  .actions((self) => ({
    handleResize() {
      self.width = window.innerWidth
      self.height = window.innerHeight
    },
  }))
  .actions((self) => {
    const handleResize = self.handleResize
    function afterCreate() {
      window.addEventListener('resize', handleResize)
    }
    function beforeDestroy() {
      window.removeEventListener('resize', handleResize)
    }

    return { afterCreate, beforeDestroy }
  })
  .views((self) => ({
    get isLarge() {
      return self.width >= LG
    },
  }))
