import { ReactComponent as Univ0 } from '../assets/svg/texture-universities-0.svg'
import { ReactComponent as Univ1 } from '../assets/svg/texture-universities-1.svg'
import { ReactComponent as Univ2 } from '../assets/svg/texture-universities-2.svg'
import { ReactComponent as Accl0 } from '../assets/svg/texture-accelerators-0.svg'
import { ReactComponent as Accl1 } from '../assets/svg/texture-accelerators-1.svg'
import { ReactComponent as Accl2 } from '../assets/svg/texture-accelerators-2.svg'
import { ReactComponent as Work0 } from '../assets/svg/texture-coworking-0.svg'
import { ReactComponent as Work1 } from '../assets/svg/texture-coworking-1.svg'
import { ReactComponent as Work2 } from '../assets/svg/texture-coworking-2.svg'
import { FunctionComponent, SVGProps } from 'react'
import { Texture } from '../lib/data'
import { SIFTED_BLUE } from '../lib/constants'

export type TextureComponent = FunctionComponent<SVGProps<SVGSVGElement> & { title?: string }>

export const Textures: Record<Texture, TextureComponent[]> = {
  Universities: [Univ0, Univ1, Univ2],
  Accelerators: [Accl0, Accl1, Accl2],
  Coworking_Spaces: [Work0, Work1, Work2],
}

export const TexturesColors: Record<Texture, string> = {
  Universities: SIFTED_BLUE,
  Accelerators: '#0D7A6D',
  Coworking_Spaces: '#4B412A',
}
