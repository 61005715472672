import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useMst } from '../state'
import { SelectTexture } from './SelectTexture'
import { ReactComponent as Open } from '../assets/svg/open.svg'
import { ReactComponent as ArrowLeft } from '../assets/svg/arrow-left.svg'
import { ReactComponent as Logo } from '../assets/svg/logo.colors.svg'
import { ReactComponent as ArrowRight } from '../assets/svg/arrow-right.svg'
import { SelectSector } from './SelectSector'
import { LegendInvestment } from './LegendInvestment'
import { PanelCard } from './PanelCard'
import { LegendSector } from './LegendSector'
import { Pill } from '../ui/Pill'

const CardBlock = (props: { title?: string; description?: string; children?: React.ReactNode }) => {
  return (
    <div className="space-y-3">
      <div>
        {props.title && <div className="text-lg leading-6 font-bold">{props.title}</div>}
        {props.description && (
          <div className="text-sm" style={{ color: '#8F8F8F' }}>
            {props.description}
          </div>
        )}
      </div>
      <div>{props.children}</div>
    </div>
  )
}

interface Props {
  closed?: boolean
  onCloseChange?: (closed: boolean) => void
  className?: string
  style?: React.CSSProperties
}

export const PanelLeft = observer(function PanelLeft({
  className = '',
  style,
  closed,
  onCloseChange,
}: Props) {
  const {
    router,
    map: { activeRegion, activeSectors, setActiveRegion },
    device: { isLarge },
  } = useMst()

  const open = !closed
  const showCards = open || isLarge
  return (
    <div className={`${className} space-y-2 scrollbar-hide ${open ? 'z-20' : ''}`} style={style}>
      <PanelCard sticky>
        <div className="flex justify-between items-center">
          {!isLarge && (
            <ArrowLeft
              fill="none"
              className="w-6 h-6 text-purplyBlue"
              onClick={() => {
                activeRegion ? setActiveRegion(null) : router.push('/')
                onCloseChange?.(true)
              }}
            />
          )}
          <Link to="/">
            <Logo className="w-40 lg:w-full" />
          </Link>
          {onCloseChange && (
            <Open
              className={`w-6 h-6 transform ${open ? 'rotate-45' : 'rotate-0'} text-purplyBlue`}
              fill="none"
              onClick={() => onCloseChange(!closed)}
            />
          )}
        </div>
      </PanelCard>

      {showCards && (
        <>
          <PanelCard>
            <CardBlock title="Tech investment per UK region">
              {activeSectors.length > 0 ? (
                <LegendSector />
              ) : (
                <LegendInvestment
                  width={85}
                  minValue={'£50m'}
                  maxValue={'+£1000m'}
                  minDiameter={10}
                  maxDiameter={20}
                />
              )}
            </CardBlock>

            <CardBlock title="Tech sectors" description="Compare up to three sectors">
              <SelectSector />
            </CardBlock>
          </PanelCard>

          {!activeRegion && (
            <PanelCard>
              <CardBlock title="Ecosystem" description="Select to see on the map">
                <SelectTexture />
              </CardBlock>
            </PanelCard>
          )}

          {isLarge && (
            <div className="ml-4">
              {activeRegion ? (
                <Pill
                  text="Go back to the UK map /"
                  className="w-full text-purplyBlue px-0"
                  leftIcon={() => <ArrowRight className="transform rotate-180" width={28} />}
                  onClick={() => {
                    setActiveRegion(null)
                    onCloseChange?.(true)
                  }}
                />
              ) : (
                <Link to="/">
                  <Pill
                    text="Homepage /"
                    className="w-full text-purplyBlue px-0"
                    leftIcon={() => <ArrowRight className="transform rotate-180" width={28} />}
                  />
                </Link>
              )}
            </div>
          )}
        </>
      )}
    </div>
  )
})
