import React, { useState } from 'react'
import { ParentSize } from '@visx/responsive'
import { Radar } from '../components/Radar'
import { LifeQualityDataByRegion } from '../lib/data'
import { Region, REGIONS, ALFA_INDEX } from '../lib/constants'
import { LifeQualityLegend } from './LifeQualityLegend'

export const LifeQualityViz = () => {
  const [selectedRegion, setSelectedRegion] = useState<Region>(REGIONS[0])
  const onRegionChange = (region: Region) => setSelectedRegion(region)

  return (
    <div>
      <div className="mb-5">
        <div className="font-bold text-lg">Comparing quality of life between UK regions</div>
        <div className="text-sm text-gray-sft-dark">
          The quality of life data is based on {LifeQualityDataByRegion[selectedRegion].Name}.
        </div>
      </div>
      <div className="lg:flex">
        <RegionFilter onRegionChange={onRegionChange} selectedRegion={selectedRegion} />
        <div className="w-full flex flex-col items-center justify-center">
          <div className="w-52 h-52 lg:w-full lg:h-full overflow-visible">
            <ParentSize>
              {(parent) => (
                <Radar
                  width={parent.width}
                  height={parent.height}
                  lifeQualityRegion={LifeQualityDataByRegion[selectedRegion]}
                />
              )}
            </ParentSize>
          </div>
          <div className="flex items-center mt-4">
            <div className="mx-3">&middot;&middot;&middot;&middot;&middot;</div>
            <div className="text-xs text-gray-sft-dark">National average</div>
          </div>
        </div>
      </div>
      <LifeQualityLegend className="lg:h-10 text-sm" />
    </div>
  )
}

interface RegionFilterProps {
  onRegionChange: (region: Region) => void
  selectedRegion: Region
}
const RegionFilter = ({ onRegionChange, selectedRegion }: RegionFilterProps) => (
  <div className="flex flex-col items-start flex-wrap h-60 lg:flex-nowrap lg:h-full lg:w-56">
    {REGIONS.map((region, i) => {
      const isSelectedRegion = selectedRegion === region
      return (
        <div
          key={i}
          className={`${
            isSelectedRegion
              ? 'text-pink-sft font-bold bg-gray-sft-light rounded-2xl px-4'
              : 'text-gray-sft-dark'
          } hover:text-pink-sft p-1  cursor-pointer`}
          onClick={() => onRegionChange(region)}
        >
          {region}
        </div>
      )
    })}
  </div>
)
