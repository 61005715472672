import { observer } from 'mobx-react-lite'
import React from 'react'
import navItems from '../navItems.json'
import { Navigation } from '@siftedeu/sifted-shared-components'

import { ReactComponent as Logo } from '../assets/svg/logo.colors.svg'
import { useMst } from '../state'
import { LogoMicrosoft } from './LogoMicrosoft'

export const Header = observer((p: { goToHomepage?: boolean }) => {
  const { router } = useMst()
  return (
    <div>
      <div className="lg:h-16 h-14" />
      <Navigation menuItems={navItems} variant="opaque" />
      <div className="flex items-end flex-col lg:my-16 my-7 relative">
        <div className="w-full">
          <Logo
            className={`mx-auto w-56 h-10 lg:w-uk-logo-homepage ${
              p.goToHomepage ? 'cursor-pointer' : ''
            }`}
            onClick={() => p.goToHomepage && router.push('/')}
          />
        </div>

        <div className="w-full">
          <div className="mx-auto max-w-mobile-card flex justify-end">
            <div className="lg:absolute right-0 top-1/2 mt-9 lg:mt-0 transform lg:-translate-y-1/2 space-y-2">
              <div className="font-bold text-xs lg:text-sm">In partnership with</div>

              <LogoMicrosoft className="w-20 lg:w-36" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
