import React from 'react'
import ReactMarkdown from 'react-markdown'

export const MarkdownToReactComponent = ({ text }: { text: string }) => (
  <ReactMarkdown
    components={{
      a: ({ node, ...props }) => <a className="text-pink-sft font-bold" target="_blank" {...props} />,
    }}
  >
    {text}
  </ReactMarkdown>
)
