import React from 'react'

interface Props {
  value: number | string
  description: string
}

export const ValueDescription = ({ value, description }: Props) => {
  return (
    <div className="-mx-2.5 text-lg flex flex-wrap items-center">
      <div className="font-bold mx-2.5" style={{ color: '#DF4B85' }}>
        {value}
      </div>
      <div className="mx-2.5">{description}</div>
    </div>
  )
}
