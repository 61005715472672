import React from 'react'
import { AnimatedDataset } from 'react-animated-dataset'
import { MAP, MapDevice, Region } from '../lib/constants'
import { CityDataByRegion } from '../lib/data'

interface Props {
  activeRegion: Region | null
  scale: number
  mapDevice: MapDevice
}

export const MapCityNames = ({ activeRegion, scale, mapDevice }: Props) => {
  const info = activeRegion
    ? MAP[mapDevice].regions[activeRegion].citiesNames.filter(({ name }) =>
        CityDataByRegion[activeRegion].map((d) => d.Name).includes(name)
      )
    : []

  return (
    <g className="--city-names">
      <AnimatedDataset
        dataset={info}
        tag="text"
        attrs={{
          x: (i: any) => i.x,
          y: (i: any) => i.y,
          textAnchor: (i: any) => i.textAnchor,
          opacity: 1,
          text: (i: any) => i.name,
          transform: 'translate(0 0)',
          fontWeight: 'bold',
          fontSize: 20 / scale,
        }}
        init={{
          transform: (i: any) => `translate(${info.includes(i) ? -20 : 20} 0)`,
          opacity: 0,
        }}
        duration={200}
        keyFn={(i: any) => i.name}
      />
    </g>
  )
}
