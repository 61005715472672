import React from 'react'
import { MapBordersAnimated } from './MapBordersAnimated'
import { MapGrid } from './MapGrid'
import { MapBubbles } from './MapBubbles'
import { MapLayer } from './MapLayer'
import { MapBordersInteractive } from './MapBordersInteractive'
import { MapTextures } from './MapTextures'
import { useMst } from '../state'
import { observer } from 'mobx-react-lite'
import { MapCityNames } from './MapCityNames'
import { noop } from 'lodash-es'

interface Props {
  className?: string
}

export const Map = observer(function Map({ className = '' }: Props) {
  const {
    mapScaleFactor,
    mapDevice,
    device: { isLarge },
    map: {
      activeRegion,
      hoveredRegion,
      activeTexture,
      activeSectors,
      sectorColors,
      setActiveRegion,
      setHoveredRegion,
      resetHoveredRegion,
    },
  } = useMst()

  return (
    <div className={className}>
      <div className="relative">
        <MapLayer type="canvas">
          <MapGrid />
        </MapLayer>
        <MapLayer type="svg">
          <MapBordersAnimated
            activeRegion={activeRegion}
            hoveredRegion={hoveredRegion}
            mapDevice={mapDevice}
          />
        </MapLayer>
        <MapLayer type="canvas">
          <MapBubbles
            activeRegion={activeRegion}
            hoveredRegion={hoveredRegion}
            activeTexture={activeTexture}
            activeSectors={activeSectors}
            sectorColors={sectorColors}
            mapDevice={mapDevice}
          />
        </MapLayer>
        <MapLayer type="svg">
          {!activeRegion && activeTexture && (
            <MapTextures texture={activeTexture} mapDevice={mapDevice} />
          )}

          <MapCityNames activeRegion={activeRegion} scale={mapScaleFactor} mapDevice={mapDevice} />

          <MapBordersInteractive
            activeRegion={activeRegion}
            mapDevice={mapDevice}
            onClick={isLarge ? setActiveRegion : setHoveredRegion}
            onMouseEnter={isLarge ? setHoveredRegion : noop}
            onMouseLeave={isLarge ? resetHoveredRegion : noop}
          />
        </MapLayer>
      </div>
    </div>
  )
})
