import React from 'react'

export const LegendSector = () => {
  return (
    <svg width="186" height="64" viewBox="0 0 186 64" fill="none">
      <path
        opacity="0.2"
        d="M15 63C17.7614 63 20 60.7614 20 58C20 55.2386 17.7614 53 15 53C12.2386 53 10 55.2386 10 58C10 60.7614 12.2386 63 15 63Z"
        fill="#797979"
      />
      <path
        opacity="0.2"
        d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71576 0 0 6.71573 0 15C0 23.2843 6.71576 30 15 30Z"
        fill="#797979"
      />
      <g transform="translate(40 12)">
        <text className="text-xs" fill="#797979">
          <tspan>Non selected emerging</tspan>
          <tspan dy="15" x="0">
            tech sector
          </tspan>
        </text>
      </g>
      <g transform="translate(40 61.5)">
        <text className="text-xs" fill="#797979">
          <tspan>Other investment</tspan>
        </text>
      </g>
    </svg>
  )
}
