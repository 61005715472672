import { useEffect } from 'react'
import gsap from 'gsap'
import * as canova from 'canova'
import { useFrame, useRenderControls } from './Canvas'
import { useConst } from '../hook/useConst'
import {
  BubbleStyle,
  DEFAULT_BUBBLE_STYLE,
  MAP,
  MAP_BG_COLOR,
  BUBBLE_MAX_RADIUS,
  Region,
  SIFTED_PINK,
  SIFTED_PINK_LIGHT,
  MapDevice,
} from '../lib/constants'
import { CityData, RegionData, RegionDatum, Sector, SECTORS, Texture } from '../lib/data'
import { buildRadiusScale, genGrid } from '../lib/data-utils'
import { flatten, sumBy, times } from 'lodash-es'
import { MaybeArray } from '../lib/types'

interface GroupInfo {
  name: string
  bubbles: number[]
}

const STYLES = {
  Region: { r: 1, fill: SIFTED_PINK },
  OtherRegion: { r: 3, fill: MAP_BG_COLOR },
  MissingData: { r: 3, fill: 'black' },
  SectorNotSelected: { fill: '#d8d8d8', r: BUBBLE_MAX_RADIUS },
  SectorMissing: { fill: '#d8d8d8', r: 5 },
  Sector: (fill?: string) => ({ fill: fill ?? 'black', r: BUBBLE_MAX_RADIUS }),
}

const genEmptyGrid = () => genGrid().map((cell) => ({ ...cell, ...DEFAULT_BUBBLE_STYLE }))
const genDefaultBubbles = () => genGrid().map(() => ({ ...DEFAULT_BUBBLE_STYLE }))

function styleBubbles(
  bubbles: BubbleStyle[],
  indices: number[],
  styles: MaybeArray<BubbleStyle>
): void {
  indices.forEach((bubbleIndex, index) => {
    const target = Array.isArray(styles) ? styles[index] : styles
    bubbles[bubbleIndex] = { ...bubbles[bubbleIndex], ...target }
  })
}

function styleBubblesByInvestment<T extends RegionDatum>(
  bubbles: BubbleStyle[],
  data: T[],
  groups: GroupInfo[],
  isDatumGroupHighlighted: (datum: T) => boolean = () => true
) {
  const getterInvestment = (datum: T) => datum.Total.value
  const radiusScale = buildRadiusScale(data, getterInvestment)

  groups.forEach((group) => {
    const datum = data.find((datum) => datum.Name === group.name)

    if (datum === undefined) {
      styleBubbles(bubbles, group.bubbles, STYLES.Region)
    } else {
      const r = radiusScale(getterInvestment(datum))
      const fill = isDatumGroupHighlighted(datum) ? SIFTED_PINK : SIFTED_PINK_LIGHT
      styleBubbles(bubbles, group.bubbles, { r, fill })
    }
  })
}

function styleBubblesBySectors<T extends RegionDatum>(
  bubbles: BubbleStyle[],
  data: T[],
  groups: GroupInfo[],
  activeSectors: Sector[],
  sectorColors: Record<string, string | undefined>
) {
  groups.forEach((group) => {
    const datum = data.find((datum) => datum.Name === group.name)

    if (datum === undefined) {
      styleBubbles(bubbles, group.bubbles, STYLES.Region)
    } else {
      const bubblesTotal = group.bubbles.length

      const total = datum.Total.value
      const knownTotal = sumBy(SECTORS, (s) => datum[s]?.value ?? 0)
      const unknownTotal = total - knownTotal

      const sectors = activeSectors
        .map((sector) => {
          const hasValue = datum[sector]?.value !== undefined
          const value = datum[sector]?.value ?? 0
          const perc = value / total
          const bubbles = hasValue ? Math.max(1, Math.round(perc * bubblesTotal)) : 0

          return { sector, bubbles }
        })
        .sort((a, b) => a.bubbles - b.bubbles)

      const unknownBubbles = Math.round((unknownTotal / total) * bubblesTotal)
      const freeBubbles = bubblesTotal - unknownBubbles - sumBy(sectors, 'bubbles')

      const sectorBubbles = flatten([
        ...times(unknownBubbles, () => STYLES.SectorMissing),
        ...times(freeBubbles, () => STYLES.SectorNotSelected),
        ...sectors.map((s) => times(s.bubbles, () => STYLES.Sector(sectorColors[s.sector]))),
      ])

      styleBubbles(bubbles, group.bubbles, sectorBubbles)
    }
  })
}

const genUkBubbles = (
  hoveredRegion: Region | null,
  activeSectors: Sector[],
  sectorColors: Record<string, string | undefined>,
  activeTexture: Texture | null,
  mapDevice: MapDevice
) => {
  const bubbles = genDefaultBubbles()

  if (activeSectors.length > 0) {
    styleBubblesBySectors(
      bubbles,
      RegionData,
      MAP[mapDevice].UK.regionsBubbles,
      activeSectors,
      sectorColors
    )
  } else {
    const isHighlighted = (datum: RegionDatum) => {
      const isHovered = datum.Name === hoveredRegion
      const hasTexture = activeTexture !== null
      return !hasTexture || isHovered
    }
    styleBubblesByInvestment(bubbles, RegionData, MAP[mapDevice].UK.regionsBubbles, isHighlighted)
  }

  return bubbles
}

const genRegionBubbles = (
  activeRegion: Region,
  activeSectors: Sector[],
  sectorColors: Record<string, string | undefined>,
  mapDevice: MapDevice
) => {
  const bubbles = genDefaultBubbles()
  const region = MAP[mapDevice].regions[activeRegion]

  if (activeSectors.length > 0) {
    styleBubblesBySectors(bubbles, CityData, region.citiesBubbles, activeSectors, sectorColors)
  } else {
    styleBubblesByInvestment(bubbles, CityData, region.citiesBubbles)
  }

  styleBubbles(bubbles, region.regionBubbles, STYLES.Region)
  styleBubbles(bubbles, region.othersBubbles, STYLES.OtherRegion)

  return bubbles
}

interface Props {
  activeRegion: Region | null
  hoveredRegion: Region | null
  activeTexture: Texture | null
  activeSectors: Sector[]
  sectorColors: Record<string, string | undefined>
  mapDevice: MapDevice
}

export const MapBubbles = ({
  activeRegion,
  hoveredRegion,
  activeTexture,
  activeSectors,
  sectorColors,
  mapDevice,
}: Props) => {
  const bubbles = useConst(() => genEmptyGrid())

  useFrame(({ ctx }) => {
    canova.draw(
      ctx,
      bubbles
        .filter((circle) => circle.r !== DEFAULT_BUBBLE_STYLE.r)
        .map((circle) => canova.circle(circle.x, circle.y, circle.r, circle))
    )
  })

  const { play, stop } = useRenderControls()

  useEffect(() => {
    const newBubbles = activeRegion
      ? genRegionBubbles(activeRegion, activeSectors, sectorColors, mapDevice)
      : genUkBubbles(hoveredRegion, activeSectors, sectorColors, activeTexture, mapDevice)

    const timeline = gsap.timeline({ onStart: play, onComplete: stop })

    bubbles.forEach((circle) => {
      const target = {
        ...DEFAULT_BUBBLE_STYLE,
        ...newBubbles[circle.index],
        duration: 0.6,
      }

      timeline.to(circle, target, 0)
    })
  }, [activeRegion, hoveredRegion, activeTexture, activeSectors, sectorColors, mapDevice])

  return null
}
