import React, { useState } from 'react'
import { noop } from 'lodash-es'

export const FooterSifted = () => {
  const [email, setEmail] = useState('')
  return (
    <footer
      className="
  ssc-py-4 ssc-text-white
  lg:ssc-relative
  ssc-bg-dark-blue
  md:ssc-px-4
  lg:ssc-py-12
  xl:ssc-px-10
  2xl:ssc-px-16
  ssc-font-rg
"
    >
      <div
        className="
    ssc-flex ssc-flex-col ssc-justify-between ssc-py-6
    md:ssc-flex-row
    ssc-items-between
    lg:ssc-px-0
    2xl:px-10
    mx-auto
    w-auto  
  "
        style={{ maxWidth: '100rem' }}
      >
        <div
          className="
      ssc-content-around ssc-justify-between ssc-hidden ssc-w-full ssc-pr-4
      lg:ssc-flex lg:ssc-flex-row
      ssc-items-around
    "
        >
          <div className="">
            <h3 className="ssc-pb-3 ssc-text-xl ssc-font-bold">Browse</h3>
            <ul>
              <li className="">
                <a
                  className="
              ssc-text-xs
              hover:ssc-text-cerise
              sifted__analytics__sidebar-sifted_link_track_secondary-menu_sectors
              sifted__analytics_footer
            "
                  href="//sifted.eu/sectors/"
                >
                  Sectors
                </a>
              </li>
              <li className="">
                <a
                  className="
              ssc-text-xs
              hover:ssc-text-cerise
              sifted__analytics__sidebar-sifted_link_track_secondary-menu_countries
              sifted__analytics_footer
            "
                  href="//sifted.eu/countries/"
                >
                  Countries
                </a>
              </li>
              <li className="">
                <a
                  className="
              ssc-text-xs
              hover:ssc-text-cerise
              sifted__analytics__sidebar-sifted_link_track_secondary-menu_newsletters
              sifted__analytics_footer
            "
                  href="https://sifted.eu/newsletter/"
                >
                  Newsletters
                </a>
              </li>
              <li className="">
                <a
                  className="
              ssc-text-xs
              hover:ssc-text-cerise
              sifted__analytics__sidebar-sifted_link_track_secondary-menu_intelligence
              sifted__analytics_footer
            "
                  href="https://sifted.eu/intelligence"
                >
                  Intelligence
                </a>
              </li>
              <li className="">
                <a
                  className="
              ssc-text-xs
              hover:ssc-text-cerise
              sifted__analytics__sidebar-sifted_link_track_secondary-menu_jobs-board
              sifted__analytics_footer
            "
                  href="https://jobs.sifted.eu"
                >
                  Jobs Board
                </a>
              </li>
            </ul>
          </div>
          <div className="ssc-flex ssc-flex-col lg:ssc-px-2">
            <h3 className="pb-3 text-xl font-bold">About Us</h3>
            <ul>
              <li>
                <a
                  className="ssc-text-xs sifted__analytics_footer hover:ssc-text-cerise"
                  href="//sifted.eu/about"
                >
                  About
                </a>
              </li>
              <li>
                <a
                  className="ssc-text-xs sifted__analytics_footer hover:ssc-text-cerise"
                  href="//sifted.eu/press-kit"
                >
                  Press kit
                </a>
              </li>
              <li>
                <a
                  className="ssc-text-xs sifted__analytics_footer hover:ssc-text-cerise"
                  href="//sifted.eu/terms-of-use"
                >
                  Terms of use
                </a>
              </li>
              <li>
                <a
                  className="ssc-text-xs sifted__analytics_footer hover:ssc-text-cerise"
                  href="//sifted.eu/privacy-policy"
                >
                  Privacy policy
                </a>
              </li>
              <li>
                <a
                  className="ssc-text-xs sifted__analytics_footer hover:ssc-text-cerise"
                  href="//sifted.eu/controllership"
                >
                  Controllership
                </a>
              </li>
              <li>
                <a
                  className="ssc-text-xs sifted__analytics_footer hover:ssc-text-cerise"
                  href="mailto:hello@sifted.eu"
                >
                  Contact us
                </a>
              </li>
            </ul>
          </div>
          <div className="ssc-flex ssc-flex-col lg:ssc-pr-12">
            <h3 className="ssc-pb-3 ssc-text-xl ssc-font-bold">More</h3>
            <ul className="ssc-flex ssc-flex-col">
              <li>
                <a
                  className="ssc-text-xs sifted__analytics_footer hover:ssc-text-cerise"
                  href="//sifted.eu/jobs"
                >
                  Join our team
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div
          className="
      ssc-flex ssc-flex-col ssc-items-center ssc-justify-start ssc-w-full
      lg:ssc-border-l lg:ssc-border-r
      ssc-border-darkGray
      lg:ssc-px-32
    "
        >
          {/* <!-- Begin Mailchimp Signup Form --> */}
          <div className="ssc-w-full ssc-px-4" id="mc_embed_signup">
            <h3
              className="
          ssc-mb-4 ssc-text-lg ssc-text-center
          lg:ssc-text-xl
          ssc-font-bold
          lg:ssc-text-left
        "
            >
              Get the best of Sifted in your inbox
            </h3>
            <form
              className="
          ssc-flex ssc-flex-col ssc-w-full
          validate
          sifted__analytics__newsletter-signup
          sifted__newsletter-form__data-collection
        "
              action="https://sifted-subscription-utils.herokuapp.com/newsletters/subscribe-mailchimp-embed/post?u=f728a14fdc520a63f329940f5&amp;id=3d44334990&amp;SOURCEFORM=footer&amp;S_INTEREST=Main%20NL"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              target="_blank"
            >
              <div
                className="
            ssc-flex ssc-flex-col ssc-items-stretch ssc-my-2
            md:ssc-flex-row md:ssc-items-stretch md:ssc-justify-center
            lg:ssc-justify-start
          "
                id="mc_embed_signup_scroll"
              >
                <div className="mc-field-group lg:ssc-mr-3">
                  <div className="fx-relay-email-input-wrapper">
                    <input
                      className="
                  ssc-w-full
                  ssc-px-4
                  ssc-py-3
                  ssc-text-sm
                  ssc-text-black
                  ssc-rounded-none
                  md:ssc-w-auto md:ssc-block
                  required
                  email
                "
                      type="email"
                      style={{ minWidth: 200, paddingRight: 50 }}
                      placeholder="Enter an email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      name="EMAIL"
                      id="mce-EMAIL"
                    />
                  </div>
                </div>
                <div id="mce-responses" className="clear">
                  <div
                    className="response"
                    id="mce-error-response"
                    style={{ display: 'none' }}
                  ></div>
                  <div
                    className="response"
                    id="mce-success-response"
                    style={{ display: 'none' }}
                  ></div>
                </div>
                {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
                <div style={{ position: 'absolute', left: -5000 }} aria-hidden="true">
                  <input type="text" name="hp_value" tabIndex={-1} value="" onChange={noop} />
                </div>
                <div className="ssc-flex ssc-w-full md:ssc-w-auto">
                  <input
                    className="
                ssc-block
                ssc-w-full
                ssc-px-8
                ssc-py-3
                ssc-text-xs
                ssc-text-white
                ssc-rounded-none
                ssc-cursor-pointer
                ssc-bg-cerise
                ssc-font-bold
              "
                    type="submit"
                    value="Sign up now"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                  />
                </div>
              </div>
              <span
                className="
            ssc-text-xs ssc-text-white
            md:ssc-text-center
            lg:ssc-text-left
          "
              >
                By entering your email you agree to Sifted’s{" "}
                <a
                  className="ssc-w-full ssc-underline hover:ssc-text-cerise"
                  href="https://sifted.eu/terms-of-use"
                  target="_blank"
                >
                  Terms of Use
                </a>
              </span>
              <span
                className="
            ssc-text-xs ssc-text-white
            md:ssc-text-center
            lg:ssc-text-left
            ssc-mt-2
          "
              >
                This site is protected by reCAPTCHA and the Google{" "}
                <a href="https://policies.google.com/privacy" className="underline" target="_blank">
                  Privacy Policy
                </a>
                {" "}and{" "}
                <a href="https://policies.google.com/terms" className="underline" target="_blank">
                  Terms of Service
                </a>
                {" "}apply.
              </span>
            </form>
          </div>
          {/* <!--End mc_embed_signup--> */}
        </div>
        <div className="ssc-hidden lg:ssc-block lg:ssc-px-6">
          <p className="ssc-mb-3 ssc-text-xl ssc-font-bold">Sifted is</p>
          <div className="ssc-py-4 ssc-mb-10">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="204.057"
              height="35.487"
              viewBox="0 0 204.057 35.487"
            >
              <g id="Sifted_5A" data-name="Sifted 5A" transform="translate(199 -54.608)">
                <g id="Group_304" data-name="Group 304" transform="translate(-199 54.608)">
                  <path
                    id="Path_108"
                    data-name="Path 108"
                    d="M76.853,114.377a1.773,1.773,0,0,0-1.59.659,3.747,3.747,0,0,0-.437,2.041v.052a3.879,3.879,0,0,0,.437,2.035,1.743,1.743,0,0,0,1.616.7,1.612,1.612,0,0,0,1.441-.709A3.682,3.682,0,0,0,78.8,117.1q0-2.722-1.946-2.725m-2.027-3.865V113c0,.305,0,.588-.02.851s-.03.472-.046.624h.066a2.533,2.533,0,0,1,.845-.76,2.758,2.758,0,0,1,1.381-.309,2.72,2.72,0,0,1,2.153.934,5.174,5.174,0,0,1-.008,5.573,2.714,2.714,0,0,1-2.153.939,2.743,2.743,0,0,1-1.381-.309,2.642,2.642,0,0,1-.845-.737h-.09l-.241.913h-.846v-10.2Z"
                    transform="translate(-73.642 -110.512)"
                    fill="#fff"
                  ></path>
                  <path
                    id="Path_109"
                    data-name="Path 109"
                    d="M84.406,116.591l-1.06.039a3.589,3.589,0,0,0-1.858.431,1.232,1.232,0,0,0-.518,1.06,1.041,1.041,0,0,0,.369.887,1.511,1.511,0,0,0,.946.282,2.291,2.291,0,0,0,1.511-.507,1.892,1.892,0,0,0,.611-1.549Zm-1.41-3.8a2.809,2.809,0,0,1,1.946.578,2.379,2.379,0,0,1,.633,1.838v4.9h-.859l-.228-1.021h-.054a3.293,3.293,0,0,1-.993.874,3.04,3.04,0,0,1-1.422.28,2.509,2.509,0,0,1-1.625-.518,1.946,1.946,0,0,1-.643-1.615,1.9,1.9,0,0,1,.846-1.658,4.882,4.882,0,0,1,2.6-.633l1.22-.042v-.436a1.629,1.629,0,0,0-.389-1.248,1.6,1.6,0,0,0-1.1-.35,3.383,3.383,0,0,0-1.072.17,7.769,7.769,0,0,0-.953.38l-.365-.887a5.485,5.485,0,0,1,1.116-.434A4.939,4.939,0,0,1,83,112.787"
                    transform="translate(-72.015 -109.907)"
                    fill="#fff"
                  ></path>
                  <path
                    id="Path_110"
                    data-name="Path 110"
                    d="M89.076,120.242a3.6,3.6,0,0,1-1.7-.389,2.716,2.716,0,0,1-1.161-1.21,4.51,4.51,0,0,1-.424-2.092,4.651,4.651,0,0,1,.437-2.161,2.768,2.768,0,0,1,1.2-1.222,3.763,3.763,0,0,1,1.733-.389,4.911,4.911,0,0,1,1.05.114,3.832,3.832,0,0,1,.841.275l-.362.982a7.04,7.04,0,0,0-.751-.228,3.249,3.249,0,0,0-.806-.108q-2.122,0-2.119,2.725a3.293,3.293,0,0,0,.516,1.994,1.8,1.8,0,0,0,1.538.692,4.054,4.054,0,0,0,1.031-.127,5,5,0,0,0,.832-.3v1.048a3.293,3.293,0,0,1-.8.288,4.693,4.693,0,0,1-1.054.1"
                    transform="translate(-70.405 -109.908)"
                    fill="#fff"
                  ></path>
                  <path
                    id="Path_111"
                    data-name="Path 111"
                    d="M92.358,110.512v5.329c0,.143,0,.332-.019.565s-.024.432-.033.6h.052c.056-.071.134-.174.242-.308l.329-.41c.111-.138.209-.253.288-.342l2.3-2.429h1.381l-2.913,3.074,3.113,4.122h-1.42l-2.5-3.358-.82.712v2.646H91.19v-10.2Z"
                    transform="translate(-68.963 -110.512)"
                    fill="#fff"
                  ></path>
                  <path
                    id="Path_112"
                    data-name="Path 112"
                    d="M99.7,113.746a1.7,1.7,0,0,0-1.335.545,2.548,2.548,0,0,0-.584,1.511h3.664a2.545,2.545,0,0,0-.431-1.484,1.52,1.52,0,0,0-1.315-.571m.013-.965a2.992,2.992,0,0,1,1.583.4,2.613,2.613,0,0,1,1.027,1.132,3.858,3.858,0,0,1,.356,1.71v.712h-4.92a2.712,2.712,0,0,0,.623,1.859,2.176,2.176,0,0,0,1.672.633,5.148,5.148,0,0,0,1.213-.127,6.578,6.578,0,0,0,1.093-.369v1.035a5.66,5.66,0,0,1-1.087.355,6.308,6.308,0,0,1-1.276.114,3.775,3.775,0,0,1-1.811-.417,2.9,2.9,0,0,1-1.213-1.234,4.252,4.252,0,0,1-.437-2.027,4.73,4.73,0,0,1,.4-2.027,3,3,0,0,1,1.113-1.3,3.061,3.061,0,0,1,1.671-.456"
                    transform="translate(-67.538 -109.908)"
                    fill="#fff"
                  ></path>
                  <path
                    id="Path_113"
                    data-name="Path 113"
                    d="M105.651,119.868a1.873,1.873,0,0,0,1.6-.623,3.167,3.167,0,0,0,.464-1.886v-.215a3.933,3.933,0,0,0-.443-2.06,1.773,1.773,0,0,0-1.638-.718,1.576,1.576,0,0,0-1.43.76,3.808,3.808,0,0,0-.476,2.033,3.585,3.585,0,0,0,.476,2,1.62,1.62,0,0,0,1.445.712m-.189.979a2.69,2.69,0,0,1-2.153-.931,5.224,5.224,0,0,1,.008-5.586,3,3,0,0,1,3.516-.633,2.891,2.891,0,0,1,.865.75h.082c-.02-.115-.038-.288-.056-.516s-.027-.41-.027-.545v-2.871h1.182v10.2h-.953l-.176-.965H107.7a2.751,2.751,0,0,1-.86.776,2.64,2.64,0,0,1-1.381.323"
                    transform="translate(-65.946 -110.51)"
                    fill="#fff"
                  ></path>
                  <path
                    id="Path_114"
                    data-name="Path 114"
                    d="M115.3,114.377a1.773,1.773,0,0,0-1.59.659,3.758,3.758,0,0,0-.437,2.041v.052a3.893,3.893,0,0,0,.437,2.035,1.744,1.744,0,0,0,1.616.7,1.615,1.615,0,0,0,1.443-.709,3.692,3.692,0,0,0,.479-2.056q0-2.722-1.948-2.725m-2.027-3.865V113c0,.305,0,.588-.019.851s-.03.472-.047.624h.066a2.52,2.52,0,0,1,.846-.76,2.755,2.755,0,0,1,1.383-.309,2.717,2.717,0,0,1,2.153.934,5.17,5.17,0,0,1-.006,5.573,3.04,3.04,0,0,1-3.53.633,2.634,2.634,0,0,1-.846-.737h-.094l-.242.913H112.1v-10.2Z"
                    transform="translate(-63.387 -110.512)"
                    fill="#fff"
                  ></path>
                  <path
                    id="Path_115"
                    data-name="Path 115"
                    d="M117.512,112.885h1.267l1.557,4.1q.2.551.362,1.032a7.1,7.1,0,0,1,.241.926h.053q.082-.334.253-.878t.36-1.093l1.464-4.082h1.267l-3.1,8.188a4.674,4.674,0,0,1-.982,1.625,2.189,2.189,0,0,1-1.638.6,4.282,4.282,0,0,1-.562-.033c-.162-.024-.3-.048-.417-.075v-.94c.1.018.217.035.355.054a4.04,4.04,0,0,0,.437.024,1.412,1.412,0,0,0,.993-.347,2.5,2.5,0,0,0,.6-.926l.38-.953Z"
                    transform="translate(-61.944 -109.879)"
                    fill="#fff"
                  ></path>
                </g>
                <g id="Group_305" data-name="Group 305" transform="translate(-199 73.608)">
                  <path
                    id="Path_116"
                    data-name="Path 116"
                    d="M22.693,139.566c0,1.156.315,1.42,2.446,1.42v.711H17.8v-.711c1.448,0,1.947-.127,1.947-1.393V127.86c0-1.267-.5-1.393-1.947-1.393v-.711H27.875a11.35,11.35,0,0,0,2.236-.127l.08,4.5H29.58c-.342-3.293-1.158-3.577-4.209-3.577H22.693v6.523h2.184c2.077,0,2.209-.342,2.367-1.972h.579v4.751h-.579c-.158-1.63-.29-1.946-2.367-1.946H22.693Z"
                    transform="translate(-17.8 -125.481)"
                    fill="#fff"
                  ></path>
                  <path
                    id="Path_117"
                    data-name="Path 117"
                    d="M28.744,141.67v-.711c1.447,0,1.947-.127,1.947-1.393V127.833c0-1.267-.5-1.393-1.947-1.393v-.711h6.84v.711c-1.447,0-1.946.127-1.946,1.393v11.732c0,1.267.5,1.393,1.946,1.393v.711Z"
                    transform="translate(-14.882 -125.455)"
                    fill="#fff"
                  ></path>
                  <path
                    id="Path_118"
                    data-name="Path 118"
                    d="M49.408,141.932l-10.893-13.52v11.074c0,1.289.894,1.473,2.394,1.473v.711h-5.79v-.711c1.5,0,2.342-.184,2.342-1.473V127.2a2.66,2.66,0,0,0-2.342-.76v-.711h5.13l8.36,10.759v-8.575c0-1.289-.894-1.473-2.394-1.473v-.711H52v.711c-1.5,0-2.342.184-2.342,1.473v14.019Z"
                    transform="translate(-13.182 -125.455)"
                    fill="#fff"
                  ></path>
                  <path
                    id="Path_119"
                    data-name="Path 119"
                    d="M53.776,141.7H48.015v-.711c1.52,0,2.209-.21,2.683-1.5l5.208-13.862h1.447l5.6,13.967c.5,1.238.737,1.393,1.868,1.393v.711H58.142v-.711c2.052,0,2.236-.157,1.737-1.393l-1.658-4.234h-5l-1.473,4.1c-.474,1.267.263,1.52,2.027,1.52Zm-.237-7.208h4.337l-2.262-5.707Z"
                    transform="translate(-9.743 -125.482)"
                    fill="#fff"
                  ></path>
                  <path
                    id="Path_120"
                    data-name="Path 120"
                    d="M76.2,141.932l-10.893-13.52v11.074c0,1.289.894,1.473,2.394,1.473v.711H62.049v-.711c1.393,0,2.209-.184,2.209-1.473V127.229a3.217,3.217,0,0,0-2.683-.789v-.711h5.478l8.36,10.759v-8.575c0-1.289-.894-1.473-2.393-1.473v-.711h5.786v.711c-1.5,0-2.341.184-2.341,1.473v14.019Z"
                    transform="translate(-6.128 -125.455)"
                    fill="#fff"
                  ></path>
                  <path
                    id="Path_121"
                    data-name="Path 121"
                    d="M88.548,125.757h.394l.237,4.761-.6.027c-.4-2.736-1.842-4.156-4.5-4.156-2.946,0-5.208,2.524-5.208,6.418,0,4.972,3.13,7.786,6.418,7.786a4.984,4.984,0,0,0,3.946-1.737l.447.474a6.9,6.9,0,0,1-5.734,2.66,7.994,7.994,0,0,1-8.107-8.106,8.139,8.139,0,0,1,8.207-8.36,13.27,13.27,0,0,1,3.84.921C88.2,126.441,88.39,126.2,88.548,125.757Z"
                    transform="translate(-2.325 -125.509)"
                    fill="#fff"
                  ></path>
                  <path
                    id="Path_122"
                    data-name="Path 122"
                    d="M87.8,141.67v-.711c1.447,0,1.946-.127,1.946-1.393V127.833c0-1.267-.5-1.393-1.946-1.393v-.711h6.84v.711c-1.446,0-1.947.127-1.947,1.393v11.732c0,1.267.5,1.393,1.947,1.393v.711Z"
                    transform="translate(0.865 -125.455)"
                    fill="#fff"
                  ></path>
                  <path
                    id="Path_123"
                    data-name="Path 123"
                    d="M99.439,141.7H94.074v-.711a1.975,1.975,0,0,0,2.289-1.5l5.207-13.862h1.448l5.6,13.967c.5,1.238.736,1.393,1.868,1.393v.711h-6.682v-.711c2.052,0,2.236-.157,1.735-1.393l-1.657-4.234h-5l-1.473,4.1c-.474,1.267.263,1.52,2.027,1.52Zm-.237-7.208h4.341l-2.262-5.707Z"
                    transform="translate(2.538 -125.482)"
                    fill="#fff"
                  ></path>
                  <path
                    id="Path_124"
                    data-name="Path 124"
                    d="M107.9,141.67v-.711c1.446,0,1.947-.127,1.947-1.393V127.833c0-1.267-.5-1.393-1.947-1.393v-.711h6.813v.711c-1.448,0-1.92.127-1.92,1.393V139.67c0,1.053.526,1.184,1.393,1.184H115c3.078,0,4.077-.447,4.918-3.972l.58.079-.507,4.709Z"
                    transform="translate(6.224 -125.455)"
                    fill="#fff"
                  ></path>
                  <path
                    id="Path_125"
                    data-name="Path 125"
                    d="M137.11,125.625l.079,4.761h-.605c-.342-3.42-1.158-3.84-4.208-3.84H131.4v13.021c0,1.236.474,1.42,2.446,1.42v.711h-7.865v-.711c1.973,0,2.472-.184,2.472-1.42V126.546h-.973c-3.051,0-3.867.421-4.209,3.84h-.6l.079-4.761a11.35,11.35,0,0,0,2.236.127h9.885A11.368,11.368,0,0,0,137.11,125.625Z"
                    transform="translate(10.163 -125.482)"
                    fill="#fff"
                  ></path>
                  <path
                    id="Path_126"
                    data-name="Path 126"
                    d="M135.295,141.67v-.711c1.447,0,1.946-.127,1.946-1.393V127.833c0-1.267-.5-1.393-1.946-1.393v-.711h6.84v.711c-1.447,0-1.947.127-1.947,1.393v11.732c0,1.267.5,1.393,1.947,1.393v.711Z"
                    transform="translate(13.529 -125.455)"
                    fill="#fff"
                  ></path>
                  <path
                    id="Path_127"
                    data-name="Path 127"
                    d="M161.02,125.73v.711c-1.448,0-2.079.079-1.947,1.341l1.236,11.837c.127,1.21.6,1.341,2.027,1.341v.711h-6.813v-.711c1.421,0,1.92-.127,1.815-1.341l-1.289-12.442-5.023,14.756h-.21l-4.866-14.756-1.21,12.413c-.127,1.267.736,1.368,2.184,1.368v.711h-5.314v-.711c1.445,0,1.946-.237,2.052-1.368l1.21-11.81c.127-1.267-.5-1.341-1.947-1.341v-.711h5.6l3.367,10.893,3.63-10.893Z"
                    transform="translate(15.213 -125.455)"
                    fill="#fff"
                  ></path>
                  <path
                    id="Path_128"
                    data-name="Path 128"
                    d="M169.144,135.825h-.579c-.157-1.63-.289-1.946-2.367-1.946h-2.421v5.786c0,1.053.527,1.184,1.393,1.184h1.315c3.078,0,4.077-.447,4.92-3.972l.579.079-.552,4.709H158.884v-.711c1.446,0,1.947-.127,1.947-1.393V127.833c0-1.267-.507-1.393-1.947-1.393v-.711h12.053l.053,3.972h-.605c-.369-2.814-1.079-3.156-4.236-3.156h-2.367v6.494H166.2c2.079,0,2.21-.342,2.367-1.972h.579Z"
                    transform="translate(19.819 -125.455)"
                    fill="#fff"
                  ></path>
                  <path
                    id="Path_129"
                    data-name="Path 129"
                    d="M178.737,138.439c0-1.446-.921-2.184-2.5-3.051l-2.526-1.315c-1.9-1.026-3.025-2.132-3.025-4.131a4.608,4.608,0,0,1,4.92-4.419c1.919,0,2.893.921,3.445.921.369,0,.526-.237.684-.684h.447l.237,4.473-.579.025a4.18,4.18,0,0,0-4.053-3.84,2.448,2.448,0,0,0-2.736,2.367c0,1.52,1.158,2.132,2.421,2.841l2.182,1.158c2.052,1.1,3.5,2.236,3.5,4.366,0,2.814-2.419,4.84-5.471,4.84-2.131,0-3.183-1.026-3.762-1.026-.369,0-.552.317-.709.816h-.446l-.315-4.761.579-.079a4.5,4.5,0,0,0,4.551,4.18C177.291,141.122,178.737,140.359,178.737,138.439Z"
                    transform="translate(22.903 -125.512)"
                    fill="#fff"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
          <p className="ssc-font-bold">Please follow us</p>
          <ul
            className="
        ssc-flex ssc-flex-row ssc-justify-between ssc-items-center ssc-py-3
      "
          >
            <li className="">
              <a href="https://www.facebook.com/siftedeu" target="_blank">
                <svg
                  id="icon-facebook"
                  viewBox="0 0 1024 1024"
                  className="ssc-text-xl ssc-h-8 ssc-text-white"
                >
                  <path
                    className="path0"
                    fill="white"
                    mi-d="M870.4 51.2h-716.8c-56.32 0-102.4 46.080-102.4 102.4v716.8c0 56.32 46.080 102.4 102.4 102.4h358.4v-358.4h-102.4v-128h102.4v-102.4c0-112.64 61.44-189.44 194.56-189.44h92.16v133.12h-61.44c-51.2 0-71.68 35.84-71.68 71.68v87.040h133.12l-30.72 128h-102.4v358.4h204.8c56.32 0 102.4-46.080 102.4-102.4v-716.8c0-56.32-46.080-102.4-102.4-102.4z"
                    mi-fill=""
                    mi-stroke="inherit"
                    mi-stroke-width=""
                    mi-stroke-linecap=""
                    mi-stroke-linejoin=""
                    mi-stroke-miterlimit=""
                    mi-opacity=""
                    d="M870.4 51.2h-716.8c-56.32 0-102.4 46.080-102.4 102.4v716.8c0 56.32 46.080 102.4 102.4 102.4h358.4v-358.4h-102.4v-128h102.4v-102.4c0-112.64 61.44-189.44 194.56-189.44h92.16v133.12h-61.44c-51.2 0-71.68 35.84-71.68 71.68v87.040h133.12l-30.72 128h-102.4v358.4h204.8c56.32 0 102.4-46.080 102.4-102.4v-716.8c0-56.32-46.080-102.4-102.4-102.4z"
                    stroke="inherit"
                  ></path>
                </svg>
              </a>
            </li>
            <li className="">
              <a href="https://www.instagram.com/siftedeu" target="_blank">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="ssc-text-xl ssc-h-8 ssc-text-white"
                >
                  <g>
                    <path
                      fill="white"
                      d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
                    ></path>
                  </g>
                </svg>
              </a>
            </li>
            <li className="">
              <a href="https://twitter.com/sifted" target="_blank">
                <svg
                  fill="white"
                  id="icon-twitter"
                  mi-view-box="0 0 1024 1024"
                  viewBox="0 0 1024 1024"
                  className="ssc-text-xl ssc-h-8 ssc-text-white"
                >
                  <title>twitter</title>
                  <path
                    ng-repeat="path in glyph.paths"
                    className="path0"
                    mi-d="M885.76 317.44c0 10.24 0 15.36 0 25.6 0 256-194.56 547.84-547.84 547.84-107.52 0-209.92-30.72-296.96-87.040 15.36 0 30.72 5.12 46.080 5.12 92.16 0 174.080-30.72 240.64-81.92-87.040 0-153.6-56.32-179.2-133.12 10.24 0 25.6 5.12 35.84 5.12 15.36 0 35.84 0 51.2-5.12-87.040-15.36-153.6-97.28-153.6-189.44 0 0 0 0 0 0 20.48 10.24 51.2 15.36 81.92 20.48-51.2-35.84-87.040-92.16-87.040-158.72 0-35.84 10.24-66.56 25.6-97.28 97.28 112.64 240.64 189.44 404.48 199.68-5.12-15.36-5.12-30.72-5.12-46.080 0-107.52 87.040-194.56 194.56-194.56 56.32 0 107.52 25.6 143.36 61.44 46.080-10.24 87.040-25.6 122.88-46.080-15.36 46.080-46.080 81.92-87.040 107.52 40.96-5.12 76.8-15.36 112.64-30.72-30.72 40.96-61.44 71.68-102.4 97.28z"
                    mi-fill=""
                    mi-stroke="inherit"
                    mi-stroke-width=""
                    mi-stroke-linecap=""
                    mi-stroke-linejoin=""
                    mi-stroke-miterlimit=""
                    mi-opacity=""
                    d="M885.76 317.44c0 10.24 0 15.36 0 25.6 0 256-194.56 547.84-547.84 547.84-107.52 0-209.92-30.72-296.96-87.040 15.36 0 30.72 5.12 46.080 5.12 92.16 0 174.080-30.72 240.64-81.92-87.040 0-153.6-56.32-179.2-133.12 10.24 0 25.6 5.12 35.84 5.12 15.36 0 35.84 0 51.2-5.12-87.040-15.36-153.6-97.28-153.6-189.44 0 0 0 0 0 0 20.48 10.24 51.2 15.36 81.92 20.48-51.2-35.84-87.040-92.16-87.040-158.72 0-35.84 10.24-66.56 25.6-97.28 97.28 112.64 240.64 189.44 404.48 199.68-5.12-15.36-5.12-30.72-5.12-46.080 0-107.52 87.040-194.56 194.56-194.56 56.32 0 107.52 25.6 143.36 61.44 46.080-10.24 87.040-25.6 122.88-46.080-15.36 46.080-46.080 81.92-87.040 107.52 40.96-5.12 76.8-15.36 112.64-30.72-30.72 40.96-61.44 71.68-102.4 97.28z"
                    stroke="inherit"
                  ></path>
                  {/* <!-- end ngRepeat: path in glyph.paths --> */}
                </svg>
              </a>
            </li>
            <li className="">
              <a href="https://www.linkedin.com/company/siftedeu/" target="_blank">
                <svg
                  id="icon-linkedin2"
                  viewBox="0 0 32 32"
                  className="ssc-text-xl ssc-h-8 ssc-text-white"
                >
                  <path
                    fill="white"
                    d="M12 12h5.535v2.837h0.079c0.77-1.381 2.655-2.837 5.464-2.837 5.842 0 6.922 3.637 6.922 8.367v9.633h-5.769v-8.54c0-2.037-0.042-4.657-3.001-4.657-3.005 0-3.463 2.218-3.463 4.509v8.688h-5.767v-18zM2 12h6v18h-6v-18zM8 7c0 1.657-1.343 3-3 3s-3-1.343-3-3c0-1.657 1.343-3 3-3s3 1.343 3 3z"
                  ></path>
                </svg>
              </a>
            </li>
            <li className="">
              <a href="//sifted.eu/feed/?post_type=article" target="_blank">
                <svg
                  id="icon-rss2"
                  viewBox="0 0 32 32"
                  className="ssc-text-xl ssc-h-8 ssc-text-white"
                >
                  <path
                    fill="white"
                    d="M29 0h-26c-1.65 0-3 1.35-3 3v26c0 1.65 1.35 3 3 3h26c1.65 0 3-1.35 3-3v-26c0-1.65-1.35-3-3-3zM8.719 25.975c-1.5 0-2.719-1.206-2.719-2.706 0-1.488 1.219-2.712 2.719-2.712 1.506 0 2.719 1.225 2.719 2.712 0 1.5-1.219 2.706-2.719 2.706zM15.544 26c0-2.556-0.994-4.962-2.794-6.762-1.806-1.806-4.2-2.8-6.75-2.8v-3.912c7.425 0 13.475 6.044 13.475 13.475h-3.931zM22.488 26c0-9.094-7.394-16.5-16.481-16.5v-3.912c11.25 0 20.406 9.162 20.406 20.413h-3.925z"
                  ></path>
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div
        className="
    ssc-flex ssc-flex-col ssc-items-center ssc-justify-center
    wrapper
    lg:ssc-px-0
    xl:ssc-px-auto
    2xl:ssc-px-10
    lg:ssc-flex-row lg:ssc-justify-start lg:ssc-items-start
  "
      >
        <div className="ssc-w-5/6 lg:ssc-hidden lg:ssc-w-1/3">
          <p className="ssc-text-center ssc-font-bold">Please follow us</p>
          <ul
            className="
        ssc-flex ssc-flex-row ssc-justify-around ssc-items-center ssc-py-5
      "
          >
            <li className="">
              <a href="https://www.facebook.com/siftedeu" target="_blank">
                <svg
                  id="icon-facebook"
                  viewBox="0 0 1024 1024"
                  className="ssc-text-xl ssc-h-8 ssc-text-white"
                >
                  <path
                    fill="white"
                    className="path0"
                    mi-d="M870.4 51.2h-716.8c-56.32 0-102.4 46.080-102.4 102.4v716.8c0 56.32 46.080 102.4 102.4 102.4h358.4v-358.4h-102.4v-128h102.4v-102.4c0-112.64 61.44-189.44 194.56-189.44h92.16v133.12h-61.44c-51.2 0-71.68 35.84-71.68 71.68v87.040h133.12l-30.72 128h-102.4v358.4h204.8c56.32 0 102.4-46.080 102.4-102.4v-716.8c0-56.32-46.080-102.4-102.4-102.4z"
                    mi-fill=""
                    mi-stroke="inherit"
                    mi-stroke-width=""
                    mi-stroke-linecap=""
                    mi-stroke-linejoin=""
                    mi-stroke-miterlimit=""
                    mi-opacity=""
                    d="M870.4 51.2h-716.8c-56.32 0-102.4 46.080-102.4 102.4v716.8c0 56.32 46.080 102.4 102.4 102.4h358.4v-358.4h-102.4v-128h102.4v-102.4c0-112.64 61.44-189.44 194.56-189.44h92.16v133.12h-61.44c-51.2 0-71.68 35.84-71.68 71.68v87.040h133.12l-30.72 128h-102.4v358.4h204.8c56.32 0 102.4-46.080 102.4-102.4v-716.8c0-56.32-46.080-102.4-102.4-102.4z"
                    stroke="inherit"
                  ></path>
                </svg>
              </a>
            </li>
            <li className="">
              <a href="https://www.instagram.com/siftedeu" target="_blank">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="ssc-text-xl ssc-h-8 ssc-text-white"
                >
                  <g>
                    <path
                      fill="white"
                      d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
                    ></path>
                  </g>
                </svg>
              </a>
            </li>
            <li className="">
              <a href="https://twitter.com/siftedeu" target="_blank">
                <svg viewBox="0 0 32 32" className="ssc-text-xl ssc-h-8 ssc-text-white">
                  <path
                    fill="white"
                    d="M27.68 9.92c0 0.32 0 0.48 0 0.8 0 8-6.080 17.12-17.12 17.12-3.36 0-6.56-0.96-9.28-2.72 0.48 0 0.96 0.16 1.44 0.16 2.88 0 5.44-0.96 7.52-2.56-2.72 0-4.8-1.76-5.6-4.16 0.32 0 0.8 0.16 1.12 0.16 0.48 0 1.12 0 1.6-0.16-2.72-0.48-4.8-3.040-4.8-5.92 0 0 0 0 0 0 0.64 0.32 1.6 0.48 2.56 0.64-1.6-1.12-2.72-2.88-2.72-4.96 0-1.12 0.32-2.080 0.8-3.040 3.040 3.52 7.52 5.92 12.64 6.24-0.16-0.48-0.16-0.96-0.16-1.44 0-3.36 2.72-6.080 6.080-6.080 1.76 0 3.36 0.8 4.48 1.92 1.44-0.32 2.72-0.8 3.84-1.44-0.48 1.44-1.44 2.56-2.72 3.36 1.28-0.16 2.4-0.48 3.52-0.96-0.96 1.28-1.92 2.24-3.2 3.040z"
                  ></path>
                </svg>
              </a>
            </li>
            <li className="">
              <a href="https://www.linkedin.com/company/siftedeu/" target="_blank">
                <svg
                  id="icon-linkedin2"
                  viewBox="0 0 32 32"
                  className="ssc-text-xl ssc-h-8 ssc-text-white"
                >
                  <path
                    fill="white"
                    d="M12 12h5.535v2.837h0.079c0.77-1.381 2.655-2.837 5.464-2.837 5.842 0 6.922 3.637 6.922 8.367v9.633h-5.769v-8.54c0-2.037-0.042-4.657-3.001-4.657-3.005 0-3.463 2.218-3.463 4.509v8.688h-5.767v-18zM2 12h6v18h-6v-18zM8 7c0 1.657-1.343 3-3 3s-3-1.343-3-3c0-1.657 1.343-3 3-3s3 1.343 3 3z"
                  ></path>
                </svg>
              </a>
            </li>
            <li className="">
              <a href="//sifted.eu/feed/?post_type=article" target="_blank">
                <svg
                  className="ssc-text-xl ssc-h-8 ssc-text-white"
                  id="icon-rss2"
                  viewBox="0 0 32 32"
                >
                  <path
                    fill="white"
                    d="M29 0h-26c-1.65 0-3 1.35-3 3v26c0 1.65 1.35 3 3 3h26c1.65 0 3-1.35 3-3v-26c0-1.65-1.35-3-3-3zM8.719 25.975c-1.5 0-2.719-1.206-2.719-2.706 0-1.488 1.219-2.712 2.719-2.712 1.506 0 2.719 1.225 2.719 2.712 0 1.5-1.219 2.706-2.719 2.706zM15.544 26c0-2.556-0.994-4.962-2.794-6.762-1.806-1.806-4.2-2.8-6.75-2.8v-3.912c7.425 0 13.475 6.044 13.475 13.475h-3.931zM22.488 26c0-9.094-7.394-16.5-16.481-16.5v-3.912c11.25 0 20.406 9.162 20.406 20.413h-3.925z"
                  ></path>
                </svg>
              </a>
            </li>
          </ul>
          <div className="ssc-w-9/12 ssc-mx-auto ssc-mb-4 lg:ssc-hidden">
            <ul
              className="
          ssc-flex
          ssc-flex-wrap
          ssc-justify-center
          ssc-w-full
          ssc-items-around
        "
            >
              <li>
                <a className="ssc-mx-2 ssc-text-xs hover:ssc-text-cerise" href="//sifted.eu/about">
                  About
                </a>
              </li>
              <li>
                <a
                  className="ssc-mx-2 ssc-text-xs hover:ssc-text-cerise"
                  href="//sifted.eu/press-kit"
                >
                  Press kit
                </a>
              </li>
              <li>
                <a
                  className="ssc-mx-2 ssc-text-xs hover:ssc-text-cerise"
                  href="mailto:hello@sifted.eu"
                >
                  Contact us
                </a>
              </li>
              <li>
                <a
                  className="ssc-mx-2 ssc-text-xs hover:ssc-text-cerise"
                  href="//sifted.eu/terms-of-use"
                >
                  Terms of use
                </a>
              </li>
              <li>
                <a
                  className="ssc-mx-2 ssc-text-xs hover:ssc-text-cerise"
                  href="//sifted.eu/privacy-policy"
                >
                  Privacy policy
                </a>
              </li>
              <li>
                <a
                  className="ssc-mx-2 ssc-text-xs hover:ssc-text-cerise"
                  href="//sifted.eu/controllership"
                >
                  Controllership
                </a>
              </li>
            </ul>
          </div>
        </div>
        <p
          className="
      ssc-w-full ssc-text-xs ssc-text-center ssc-text-gray-500
      lg:ssc-text-left
    "
        >
          © 2021 Sifted EU Ltd. All Rights Reserved.
        </p>
      </div>
    </footer>
  )
}
