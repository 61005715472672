import React from 'react'
import { ReactComponent as CloseIcon } from '../assets/svg/close.colors.svg'

interface Props {
  title: string
  className?: string
  children?: React.ReactNode
  closable?: boolean
  onCloseClick?: () => void
}

export const CardTooltip = ({
  children,
  title,
  className = '',
  onCloseClick,
  closable = false,
}: Props) => {
  return (
    <div className={`${className} bg-white overflow-hidden w-44 rounded-sft shadow-sft`}>
      <div
        className="py-2 px-3 font-medium text-base relative"
        style={{ backgroundColor: '#f4f4f7' }}
      >
        {title}

        {closable && (
          <div
            className="absolute right-0 top-0 w-10 h-10 p-3 cursor-pointer"
            onClick={onCloseClick}
          >
            <CloseIcon className="w-full h-full" />
          </div>
        )}
      </div>

      <div className="py-2 px-3">{children}</div>
    </div>
  )
}
