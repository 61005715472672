import React, { useState } from 'react'
import { ParentSize } from '@visx/responsive'
import { HomepageCardContent } from './HomepageCardContent'
import { LifeQualityViz } from './LifeQualityViz'
import { scrollToElement } from '../lib/dom'
import { LegendInvestment } from './LegendInvestment'
import { DEIMetrics } from './DEIMetrics'
import { Sources } from '../ui/Sources'
import { BubbleMatrix } from './BubbleMatrix'
import { TechSectors } from '../lib/data'
import { capitalize } from 'lodash-es'
import { ReactComponent as BubbleMatrixBackgournd } from '../assets/svg/matrix-bubble.colors.svg'
import { ReactComponent as BubbleMatrixBackgourndMobile } from '../assets/svg/matrix-bubble-mobile.colors.svg'
import { ReactComponent as RadarChart } from '../assets/svg/radar-chart.colors.svg'
import { ReactComponent as RadarChartMobile } from '../assets/svg/radar-chart-mobile.colors.svg'
import { ReactComponent as Gantt } from '../assets/svg/gantt.colors.svg'
import { ReactComponent as GanttMobile } from '../assets/svg/gantt-mobile.colors.svg'
import { TEXT } from '../lib/constants'
import { format, unsnake } from '../lib/format'

const SECTIONS = [
  {
    title: 'Tech sectors /',
    description: TEXT.Homepage.TechSectors.Text,
    background: BubbleMatrixBackgournd,
    backgroundMobile: BubbleMatrixBackgourndMobile,
    legend: (
      <div className="text-xs lg:w-80">
        <div className="text-lg font-medium mb-4">Number of companies</div>
        <LegendInvestment
          width={163}
          minValue={'10'}
          maxValue={'100'}
          minDiameter={8}
          maxDiameter={35}
        />
        <div className="mt-9">
          <Sources>
            <Sources.Item
              label="Dealroom (2021)"
              href="https://datacommons.technation.io/dashboard"
            />
          </Sources>
        </div>
      </div>
    ),
    viz: (
      <div className="flex flex-col xl:h-full h-96">
        <div className="font-bold">Number of active companies per tech sector per region</div>
        <div className="overflow-x-auto flex-auto ">
          <ParentSize>
            {({ width, height }) =>
              width > 0 &&
              height > 0 && (
                <BubbleMatrix
                  data={TechSectors}
                  width={Math.max(500, width)}
                  height={height}
                  tickRotation={-45}
                  getterX={(d) => d.sector}
                  getterY={(d) => d.region}
                  getterRadius={(d) => d.value}
                  formatX={(s) => format(s, capitalize, unsnake)}
                />
              )
            }
          </ParentSize>
        </div>
      </div>
    ),
  },
  {
    title: 'Quality of life /',
    description: TEXT.Homepage.LifeQuality.Text,
    background: RadarChart,
    backgroundMobile: RadarChartMobile,
    legend: (
      <div className="text-xs lg:w-80">
        <Sources>
          <Sources.Item
            label="UK local authority and regional carbon dioxide emissions national statistics (2018)"
            href="https://www.gov.uk/government/statistics/uk-local-authority-and-regional-carbon-dioxide-emissions-national-statistics-2005-to-2018"
          />
          <Sources.Item
            label="Fields in Trust Green Space Index (2020)"
            href="http://www.fieldsintrust.org/green-space-index"
          />
          <Sources.Item
            label="Technation Median dig tech salary (2019)"
            href="https://technation.io/report2021/"
          />
          <Sources.Item
            label="Numbeo Statistics (2020)"
            href="https://www.numbeo.com/cost-of-living/"
          />
        </Sources>
      </div>
    ),
    viz: <LifeQualityViz />,
  },
  {
    title: 'Diversity and access to VC /',
    description: TEXT.Homepage.DeiMetrics.Text,
    background: Gantt,
    backgroundMobile: GanttMobile,
    viz: <DEIMetrics />,
    legend: (
      <div className="text-xs lg:w-80 text-gray-sft">
        <Sources>
          <Sources.Item
            label="Diversity Beyond Gender - Extend Ventures Report (2020)"
            href="https://9bbf82ab-3b38-40bc-9dfc-c7f475116ce4.filesusr.com/ugd/52d2fc_1b4c9ee497fb437d99facdc7ed847083.pdf"
          />
        </Sources>
        This data was collected by a machine learning algorithm trained to detect gender and
        ethnicity data based on public profiles.
      </div>
    ),
  },
]

export const HomepageOverviewCards = () => {
  const [selected, setSelected] = useState<number | null>(null)
  const handleSelected = (n: number) => setSelected(selected === n ? null : n)

  return (
    <>
      {SECTIONS.map(({ title, background, backgroundMobile, legend, viz, description }, i) => {
        const Background = background
        const BackgroundMobile = backgroundMobile
        const Legend = () => legend || null
        const state = selected === i ? 'SELECTED' : selected === null ? 'UNTOUCHED' : 'NOTSELECTED'
        const cardContentHeight = state === 'UNTOUCHED' ? 'h-overview-card-mobil' : ''
        const titleClass = state !== 'NOTSELECTED' ? 'mb-4' : ''

        return (
          <HomepageCardContent
            key={i}
            className={`${cardContentHeight} lg:mb-0 mb-5 xl:h-overview-card`}
            title={title}
            titleClass={titleClass}
            description={description}
            state={state}
            onClick={(e) => handleSelected(i)}
            animated
          >
            <HomepageCardContent.Background>
              <div className="flex justify-end items-end mx-auto h-full">
                <Background className="rounded-sft max-w-sm w-full object-fill object-bottom " />
              </div>
            </HomepageCardContent.Background>
            <HomepageCardContent.Legend>
              <Legend />
            </HomepageCardContent.Legend>
            <HomepageCardContent.Viz>{viz}</HomepageCardContent.Viz>

            {state === 'UNTOUCHED' && (
              <div className="w-full max-h-72 xl:hidden">
                <div className="w-full" style={{ marginBottom: '85%' }}></div>
              </div>
            )}
          </HomepageCardContent>
        )
      })}
    </>
  )
}
