import { observer } from 'mobx-react-lite'
import React from 'react'
import { capitalize } from 'lodash-es'
import { TEXTURES } from '../lib/data'
import { useMst } from '../state'
import { LegendTexture } from './LegendTexture'

export const SelectTexture = observer(function PanelTextureSelect() {
  const { activeTexture, setActiveTexture } = useMst().map
  return (
    <div className="space-y-2">
      {TEXTURES.map((texture) => {
        const label = capitalize(texture).replace('_', ' ')
        const isSelected = activeTexture === texture

        if (!isSelected)
          return (
            <div key={texture} className="flex">
              <div
                className="cursor-pointer px-2 rounded-full transition-colors duration-150 bg-gray-200 bg-opacity-0 hover:bg-opacity-100"
                onClick={() => setActiveTexture(texture)}
              >
                {label}
              </div>
            </div>
          )

        return (
          <div key={texture}>
            <div className="flex">
              <div className="bg-gray-200 flex space-x-2 px-2 rounded-full">
                <div>{capitalize(texture).replace('_', ' ')}</div>
                <div className="cursor-pointer" onClick={() => setActiveTexture(null)}>
                  &times;
                </div>
              </div>
            </div>
            <LegendTexture texture={texture} />
          </div>
        )
      })}
    </div>
  )
})
