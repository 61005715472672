import React from 'react'
import { identity, isString, max, uniq } from 'lodash-es'
import { makeLayout, LayoutBlock } from 'yogurt-layout'
import { scalePoint, scaleSqrt } from 'd3'
import { SIFTED_PINK } from '../lib/constants'

interface Props<Datum> {
  data: Datum[]
  width: number
  height: number
  getterX: (datum: Datum) => string
  getterY: (datum: Datum) => string
  getterRadius: (datum: Datum) => number
  formatX?: (v: string) => string
  formatY?: (v: string) => string
  tickRotation?: number
  maxValue?: number
  minValue?: number
}

const Y_LABELS_WIDTH = 110
const X_LABELS_SMALL_HEIGHT = 20
const X_LABELS_BIG_HEIGHT = 60

function computeLayout(width: number, height: number, xLabelsHeight: number) {
  return makeLayout({
    id: 'root',
    width,
    height,
    padding: {
      bottom: 10,
      right: 30,
    },
    children: [
      { id: 'yLabels', width: Y_LABELS_WIDTH },
      {
        id: 'verticalWrapper',
        direction: 'column',
        children: [
          { id: 'xLabels', height: xLabelsHeight },
          { id: 'xLabelsSpacing', height: 7 },
          { id: 'axis', padding: { top: 10, left: 10 }, children: [{ id: 'chart' }] },
        ],
      },
    ],
  })
}
export function BubbleMatrix<Datum>({
  data,
  width,
  height,
  tickRotation = 0,
  getterX,
  getterY,
  getterRadius,
  formatX = identity,
  formatY = identity,
  maxValue,
  minValue = 0,
}: Props<Datum>) {
  const xDomain = uniq(data.map(getterX)).filter(isString)
  const yDomain = uniq(data.map(getterY)).filter(isString)

  const radiusDomain = [minValue, maxValue ?? max(data.map(getterRadius)) ?? 1]

  const layout = computeLayout(
    width,
    height,
    tickRotation === 0 ? X_LABELS_SMALL_HEIGHT : X_LABELS_BIG_HEIGHT
  )

  const xRange = [layout.chart.left, layout.chart.right]
  const yRange = [layout.chart.top, layout.chart.bottom]

  const xScale = scalePoint(xDomain, xRange)
  const yScale = scalePoint(yDomain, yRange)

  const xDelta = Math.abs(xScale(xDomain[0])! - xScale(xDomain[1])!)
  const yDelta = Math.abs(yScale(yDomain[0])! - yScale(yDomain[1])!)

  const maxRadius = Math.min(xDelta, yDelta) / 2
  const radiusRange = [1.3, maxRadius]

  const radiusScale = scaleSqrt(radiusDomain, radiusRange)

  const xTickStyle =
    tickRotation !== 0 ? { transform: `rotate(${tickRotation})` } : { textAnchor: 'middle' }

  return (
    <svg
      className="overflow-visible"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      {/* <g opacity="0.7">
        <DebugYogurtLayout layout={layout.axis} fill="yellow" />
        <DebugYogurtLayout layout={layout.yLabels} fill="red" />
        <DebugYogurtLayout layout={layout.xLabels} fill="green" />
        <DebugYogurtLayout layout={layout.axis} fill="pink" />
        <DebugYogurtLayout layout={layout.chart} fill="blue" />
      </g> */}

      <g stroke="#F0F0F0" opacity={0.5}>
        {xDomain.map((v, i) => (
          <line
            key={i}
            x1={xScale(v)}
            x2={xScale(v)}
            y1={layout.axis.top}
            y2={layout.axis.bottom}
          />
        ))}
        {yDomain.map((v, i) => (
          <line
            key={i}
            x1={layout.axis.left}
            x2={layout.axis.right}
            y1={yScale(v)}
            y2={yScale(v)}
          />
        ))}
      </g>

      <g fill="#8F8F8F" fontSize="14">
        {xDomain.map((v, i) => (
          <g key={i} transform={`translate(${xScale(v)} ${layout.xLabels.bottom})`}>
            <text {...xTickStyle}>{formatX(v)}</text>
          </g>
        ))}

        {yDomain.map((v, i) => (
          <text key={i} x={layout.yLabels.left} y={yScale(v)} dominantBaseline="middle">
            {formatY(v)}
          </text>
        ))}
      </g>

      {data.map((datum, i) => (
        <circle
          key={i}
          cx={xScale(getterX(datum))}
          cy={yScale(getterY(datum))}
          r={radiusScale(getterRadius(datum))}
          fill={SIFTED_PINK}
        />
      ))}
    </svg>
  )
}

export const DebugYogurtLayout = ({ layout, fill }: { layout: LayoutBlock; fill: string }) => (
  <rect
    x={layout.left}
    y={layout.top}
    height={layout.height}
    width={layout.width}
    stroke={fill}
    fill={fill}
    fillOpacity="0.5"
  />
)
