import React from 'react'
import { Component } from '../lib/types'

type SourceComponent = Component<{ children: React.ReactNode }> & {
  Item: Component<{ label: string; href: string }>
}

export const Sources = (({ children }) => {
  const childrenArray = React.Children.toArray(children)
  const hasUnknownChild = childrenArray.some((item) => (item as JSX.Element).type !== Sources.Item)
  if (hasUnknownChild) throw new Error('Sources component accepts only Sources.Item as children')

  return (
    <div className="text-xxs text-gray-sft">
      Source:{' '}
      {childrenArray.map((child, i, arr) => (
        <React.Fragment key={i}>
          {child}
          {i < arr.length - 1 ? ', ' : ''}
        </React.Fragment>
      ))}
    </div>
  )
}) as SourceComponent

Sources.Item = ({ label, href }) => (
  <a className="hover:underline" href={href} target="_blank">
    {label}
  </a>
)
