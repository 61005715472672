import { difference, fromPairs } from 'lodash-es'
import { reaction } from 'mobx'
import { types as t } from 'mobx-state-tree'
import { REGIONS, Region } from '../lib/constants'
import { AvailableSectorsByRegion, Sector, SECTORS, Texture, TEXTURES } from '../lib/data'
import { tuple } from '../lib/types'

export const MapModel = t
  .model('MapModel', {
    activeRegion: t.maybeNull(t.enumeration(REGIONS)),
    hoveredRegion: t.maybeNull(t.enumeration(REGIONS)),
    activeTexture: t.maybeNull(t.enumeration(TEXTURES)),
    activeSectors: t.frozen<Sector[]>([]),
  })
  .views((self) => ({
    get sectorColors() {
      const DEFAULT_SECTOR_COLORS = ['#9CCBEF', '#AE8DF4', '#E78CB1']

      const sectorColors = SECTORS.map((sector) => {
        const index = self.activeSectors.indexOf(sector)

        const fill = index === -1 ? undefined : DEFAULT_SECTOR_COLORS[index]
        return tuple(sector, fill)
      })

      return fromPairs(sectorColors)
    },

    get availableSectors() {
      return self.activeRegion ? AvailableSectorsByRegion[self.activeRegion] : SECTORS
    },
  }))
  .actions((self) => ({
    setActiveRegion(region: Region | null) {
      self.activeRegion = region
      self.hoveredRegion = null
    },
    setHoveredRegion(region: Region) {
      self.hoveredRegion = region
    },

    resetHoveredRegion() {
      self.hoveredRegion = null
    },

    setActiveTexture(texture: Texture | null) {
      self.activeTexture = texture
    },
    addActiveSector(sector: Sector) {
      if (!self.activeSectors.includes(sector)) {
        self.activeSectors = [...self.activeSectors, sector]
      }
    },
    removeActiveSector(...sectors: Sector[]) {
      self.activeSectors = self.activeSectors.filter((s) => !sectors.includes(s))
    },
  }))
  .views((self) => ({
    isSectorActive(sector: Sector) {
      return self.activeSectors.includes(sector)
    },

    isSectorAvailable(sector: Sector) {
      return self.availableSectors.includes(sector)
    },
    get canSelectSectors() {
      return self.activeSectors.length < 3
    },
  }))
  .actions((self) => ({
    onSectorClick(sector: Sector) {
      if (self.isSectorActive(sector)) {
        self.removeActiveSector(sector)
      } else if (self.canSelectSectors) {
        self.addActiveSector(sector)
      }
    },

    onRegionChange() {
      if (self.activeRegion !== null) {
        const invalidSectors = difference(self.activeSectors, self.availableSectors)
        self.removeActiveSector(...invalidSectors)
      }
    },
  }))
  .actions((self) => {
    const disposer = reaction(() => [self.activeRegion], self.onRegionChange)

    return {
      beforeDestroy() {
        disposer()
      },
    }
  })
