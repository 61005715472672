import React from 'react'

interface Props {
  src: string
}

const ytIdRegex = /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i

export const VimeoVideo = ({ src }: Props) => {
  const [, videoId] = ytIdRegex.exec(src)!
  const aspectRatio = 16 / 9

  return (
    <div className="relative" style={{ paddingBottom: `${(1 / aspectRatio) * 100}%` }}>
      <div>
        <iframe
          className="absolute inset-0 w-full h-full"
          src={`https://player.vimeo.com/video/${videoId}`}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  )
}
