import React, { useEffect, useRef } from 'react'
import { Card } from '../ui/Card'
import { Component, HompageCardStateType } from '../lib/types'
import { SIFTED_BLUE } from '../lib/constants'
import { scrollToElement } from '../lib/dom'
import { ReactComponent as Close } from '../assets/svg/close.svg'
import { ReactComponent as Open } from '../assets/svg/open.svg'
import { MarkdownToReactComponent } from './MarkdownToReactComponent'

interface CardProps {
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
  title?: string
  titleClass?: string
  description?: string
  descriptionClass?: string
  state?: HompageCardStateType
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  animated?: boolean
}

interface ChildrenProps {
  children: React.ReactNode
}
type HomepageCardContent = Component<CardProps> & {
  Background: Component<ChildrenProps>
  Legend: Component<ChildrenProps>
  Viz: Component<ChildrenProps>
}
export const HomepageCardContent = ((props) => {
  const {
    children = null,
    className = '',
    style = {},
    title = '',
    titleClass = '',
    descriptionClass = '',
    description = '',
    state = 'UNTOUCHED',
    onClick = () => {},
    animated = false,
  } = props
  const ref = useRef<HTMLDivElement>(null)
  const childrenArray = React.Children.toArray(children)
  const backgroundChildren = childrenArray.filter(
    (child) => (child as any).type === HomepageCardContent.Background
  )
  const legendChildren = childrenArray.filter(
    (child) => (child as any).type === HomepageCardContent.Legend
  )
  const vizChildren = childrenArray.filter(
    (child) => (child as any).type === HomepageCardContent.Viz
  )

  const restOfChildren = childrenArray.filter((child) => {
    const childType = (child as any).type
    const isBackground = childType === HomepageCardContent.Background
    const isLegend = childType === HomepageCardContent.Legend
    const isViz = childType === HomepageCardContent.Viz

    return !isBackground && !isLegend && !isViz
  })

  const cardFlexGrow = state === 'NOTSELECTED' ? 'lg:flex-grow-0' : 'lg:flex-grow'
  const writingMode = state === 'NOTSELECTED' ? 'xl:writing-mode-vertical' : 'xl:writing-mode-unset'
  const displayVerticalFlex =
    state === 'NOTSELECTED' ? 'lg:flex xl:justify-center lg:items-start' : ''
  const animatedFlexGrow = animated ? `${cardFlexGrow} lg:transition-flex-grow duration-300` : ''
  const cardBackgroundColor = animated
    ? state !== 'NOTSELECTED'
      ? 'bg-white'
      : 'bg-transparent'
    : 'bg-transparent'
  const isHidden =
    state === 'SELECTED' ? 'block' : state === 'NOTSELECTED' ? 'block hidden' : 'hidden'
  const descriptionDisplay = state === 'NOTSELECTED' ? 'hidden' : ''
  const py = state === 'NOTSELECTED' ? 'py-4' : 'py-8'

  useEffect(() => {
    if (state === 'SELECTED' && ref.current && window.innerWidth <= 1280) {
      scrollToElement(ref.current, -80)
    }
  }, [state])
  return (
    <Card
      className={`${className} ${cardBackgroundColor} ${animatedFlexGrow}`}
      style={{ ...style }}
    >
      <div ref={ref} className="relative h-full z-0">
        {state === 'UNTOUCHED' && backgroundChildren}
        <div className={`lg:p-10 ${py} px-7 h-full`}>
          <div className="flex flex-col xl:flex-row h-full">
            <div className="lg:h-full flex flex-col justify-between">
              <div className={`${displayVerticalFlex} xl:h-80`}>
                <div
                  className={`flex ${
                    state === 'NOTSELECTED'
                      ? 'lg:items-center lg:justify-center'
                      : 'justify-between'
                  } ${animated ? 'cursor-pointer' : ''}`}
                  onClick={onClick}
                >
                  <div
                    className={`${titleClass} ${writingMode} text-2xl font-bold xl:mb-6 ${
                      state !== 'NOTSELECTED' ? 'xl:mr-7' : ''
                    }`}
                  >
                    {title}
                  </div>
                </div>
                <div
                  className={`${descriptionDisplay} ${descriptionClass} lg:text-lg xl:w-80 lg:mb-5 xl:mb-0`}
                >
                  <MarkdownToReactComponent text={description} />
                </div>
              </div>
              <div className={`${state === 'SELECTED' ? 'lg:block' : ''} hidden`}>
                {legendChildren}
              </div>
              {restOfChildren}
            </div>
            <div className={`${isHidden} w-full xl:pl-20 mt-10 xl:mt-0`}>{vizChildren}</div>
            <div className={`${state === 'SELECTED' ? 'block mt-9' : 'hidden'} lg:hidden`}>
              {legendChildren}
            </div>
          </div>
        </div>
        {animated && (
          <div
            className={`absolute inset-0 xl:p-10 pointer-events-none flex justify-end 
            ${state === 'NOTSELECTED' ? 'items-center px-8 xl:flex-col' : 'p-8'}`}
            onClick={onClick}
          >
            <div className="cursor-pointer pointer-events-auto">
              <ToogleCard state={state} />
            </div>
          </div>
        )}
      </div>
    </Card>
  )
}) as HomepageCardContent

HomepageCardContent.Background = ({ children }) => (
  <div className="absolute inset-0 -z-10">{children}</div>
)

HomepageCardContent.Legend = ({ children }) => <>{children}</>
HomepageCardContent.Viz = ({ children }) => <div className="h-full">{children}</div>

const ToogleCard = ({ state }: { state: HompageCardStateType }) => {
  const Button = state === 'SELECTED' ? Close : Open
  return <Button width={30} fill="none" color={SIFTED_BLUE} />
}
