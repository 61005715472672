import React from 'react'
import { Region, SIFTED_PINK } from '../lib/constants'
import { UnicornsByRegion } from '../lib/data'
import { unsnake } from '../lib/format'
import { strTuple } from '../lib/types'

interface Props {
  activeRegion: Region
}

export const Unicorns = ({ activeRegion }: Props) => {
  const datum = UnicornsByRegion[activeRegion]

  const keys = strTuple('Unicorns', 'Future_unicorns')
  return (
    <div className="grid grid-flow-row grid-cols-2">
      {keys.map((k) => (
        <div key={k} className="flex items-center space-x-3">
          <div className="font-bold text-2xl" style={{ color: SIFTED_PINK }}>
            {datum[k]}
          </div>
          <div className="text-base">{unsnake(k)}</div>
        </div>
      ))}
    </div>
  )
}
