import React from 'react'
import { chunk, round } from 'lodash-es'
import { TalentSalaryAverage, TalentSalaryMax } from '../lib/data'
import { formatPoundSteling, unsnake } from '../lib/format'
import { BigBubble } from './BigBubble'

export const TalentAndEmploymentSalaryHelp = () => (
  <div className="space-y-2">
    {chunk(Object.entries(TalentSalaryAverage), 3).map((group, i) => (
      <div key={i} className="flex flex-wrap justify-between">
        {group.map(([key, average]) => (
          <BigBubble
            key={key}
            dark
            showAverage
            max={TalentSalaryMax[key]}
            average={average}
            label={unsnake(key)}
            formattedValue={''}
            formattedAverage={formatPoundSteling(round(average, -3))}
          />
        ))}
      </div>
    ))}
  </div>
)
