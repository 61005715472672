import React from 'react'
import { Texture } from '../lib/data'
import { Textures, TexturesColors } from './texture-components'

interface Props {
  texture: Texture
}

export const LegendTexture = ({ texture }: Props) => {
  return (
    <div className="flex space-x-3" style={{ color: TexturesColors[texture] }}>
      {Textures[texture].map((Icon, i) => (
        <div className="flex flex-col items-center" key={i}>
          <Icon className="w-9 h-9" />
          <div className="text-xs">{i === 0 ? '1-10' : i === 1 ? '11-20' : '+21'}</div>
        </div>
      ))}
    </div>
  )
}
