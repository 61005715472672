import { scaleSqrt, scaleLinear } from 'd3'
import { xor } from 'lodash'
import { MAP, BUBBLE_MAX_RADIUS, Region, MapDevice } from './constants'
import { GRID } from './grid'

export const getMapBorders = (activeRegion: Region | null, mapDevice: MapDevice) => {
  const borders = (!activeRegion ? MAP[mapDevice].UK : MAP[mapDevice].regions[activeRegion]).borders
  return Object.entries(borders)
    .flatMap(([region, paths]) => paths.map((d) => ({ region, d })))
    .sort((a) => (a.region === activeRegion ? 1 : -1))
}

export const getTextureIndex = (value: number) => {
  return value <= 10 ? 0 : value <= 20 ? 1 : 2
}
export const genGrid = () => GRID.map((cell) => ({ ...cell }))

export function buildRadiusScale<T>(data: T[], mapper: (value: T) => number) {
  const values = data.map(mapper).sort((a, b) => a - b)
  const minValue = values[0]
  const maxValue = values[values.length - 2] * 1.75 // custom max value, London has a big value

  const radiusScale = scaleSqrt()
    .domain([minValue, maxValue])
    .range([2, BUBBLE_MAX_RADIUS])
    .clamp(true)

  return radiusScale
}

export function checkMissingRegion(
  datasetName: string,
  datasetRegions: string[]
  // mapDevice: MapDevice
) {
  const mapRegions = Object.keys(MAP.desktop.UK.borders)
  const unknownRegions = xor(datasetRegions, mapRegions)

  if (unknownRegions.length)
    console.error(
      `[${datasetName}]: regions mismatch` +
        `\nData: ${datasetRegions.filter((r) => unknownRegions.includes(r))}` +
        `\nMap: ${mapRegions.filter((r) => unknownRegions.includes(r))} `
    )
}
