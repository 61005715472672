import React from 'react'
import { range } from 'd3-array'
import { scaleLinear } from 'd3-scale'
import { last, meanBy } from 'lodash-es'
import { polarToCartesianCoordinates } from '../lib/geometry'
import {
  LifeQualityDatum,
  LIFE_QUALITY_FACTORS,
  LifeQualityData,
  LifeQualityFactor,
} from '../lib/data'
import {
  SIFTED_GRAY_STROKE,
  SIFTED_GRAY_DARK,
  SIFTED_PINK,
  SIFTED_PINK_TRANSPARENT,
  SIFTED_BLUE,
  ALFA_INDEX,
  RADAR_GRADIENT,
} from '../lib/constants'
import { linePath, textRadialPlacement, SvgPlacement } from '../lib/draw-utils'

const FACTORS_AMOUNT = 6

const lifeQualityAverage = LIFE_QUALITY_FACTORS.reduce((acc, factor) => {
  const mean = meanBy(LifeQualityData, (datum) => datum[factor]?.value)

  return { ...acc, [factor]: Math.round(mean) }
}, {} as Record<LifeQualityFactor, number>)

interface RadarProps {
  width: number
  height: number
  lifeQualityRegion?: LifeQualityDatum
}
export const Radar = ({ height = 0, width = 0, lifeQualityRegion }: RadarProps) => {
  const hasDatum = lifeQualityRegion !== undefined

  const size = Math.min(width, height)
  const center = [width / 2, height / 2]
  const innerSize = size / 2.5
  const sizeMin = size / 16
  const borderRadius = size / 25
  const radarRadius = innerSize + borderRadius * 2
  const angleScale = (i: number) => (i / FACTORS_AMOUNT) * Math.PI * 2
  function getCoordinates<T extends Record<LifeQualityFactor, any>>({
    dataset,
    callback,
  }: {
    dataset: T
    callback: (datum: NonNullable<T[LifeQualityFactor]>) => number
  }) {
    return LIFE_QUALITY_FACTORS.map((d, i) => ({
      datum: dataset[d],
      index: i,
    }))
      .filter((d) => d.datum)
      .map(({ datum, index }) => {
        const value = callback(datum)
        const [cx, cy] = polarToCartesianCoordinates(center, radiusScale(value), angleScale(index))
        return [cx, cy]
      })
  }

  const haloesNumber = 4
  const haloes = range(0, haloesNumber).map((d) =>
    scaleLinear([0, haloesNumber], [sizeMin, radarRadius])(d)
  )
  const polarHaloes = haloes.map((haloRadius) =>
    LIFE_QUALITY_FACTORS.map((d, i) =>
      polarToCartesianCoordinates(center, haloRadius, angleScale(i))
    )
  )
  const maxHaloRadius = Math.max(...haloes)
  const radiusScale = scaleLinear([1, 4], [maxHaloRadius, sizeMin])
  const bubbleCoordinates = lifeQualityRegion
    ? getCoordinates({ dataset: lifeQualityRegion, callback: (d) => d.value })
    : []

  const bubbleCoordinatesAverage = getCoordinates({
    dataset: lifeQualityAverage,
    callback: (d) => d,
  })

  const labelDistanceFromCenter = maxHaloRadius + 20
  const labels = LIFE_QUALITY_FACTORS.map((lqf, i) => {
    const label = !lifeQualityRegion ? '' : lifeQualityRegion[lqf]?.label ?? 'No data'
    const [cx, cy] = polarToCartesianCoordinates(center, labelDistanceFromCenter, angleScale(i))

    return {
      text: label,
      coordinates: [cx, cy],
      textOrientation: textRadialPlacement(angleScale(i)),
    }
  })

  const path = linePath(bubbleCoordinates) || ''
  const pathAverage = linePath(bubbleCoordinatesAverage) || ''

  return (
    <svg className="overflow-visible" viewBox={`0 0 ${size} ${size}`} width={size} height={size}>
      {!hasDatum && (
        <>
          <defs>
            <radialGradient id="gradient">
              <stop offset="50%" stop-color={RADAR_GRADIENT[0]} />
              <stop offset="100%" stop-color={RADAR_GRADIENT[1]} />
            </radialGradient>
          </defs>
          <RadarAreaPath
            className="--radar-area-filled-path-average"
            path={linePath(last(polarHaloes)!) ?? ''}
            fill={'url(#gradient)'}
          />
        </>
      )}

      <RadarGrid polarHaloes={polarHaloes} />
      <RadarAreaPath
        className="--radar-area-filled-path"
        path={path}
        fill={SIFTED_PINK_TRANSPARENT}
        stroke={SIFTED_PINK}
      />
      <RadarAreaPath
        className="--radar-area-filled-path-average"
        path={pathAverage}
        stroke={hasDatum ? SIFTED_BLUE : SIFTED_PINK}
        strokeWidth={hasDatum ? 1 : 2}
        strokeDasharray={hasDatum ? '1 2' : '2 4'}
      />
      <RadarBubbles
        className="--radar-area-bubbles"
        bubbles={bubbleCoordinates}
        r={5}
        fill={SIFTED_PINK}
      />
      <RadarBubbles
        className="--radar-area-bubbles-average"
        bubbles={bubbleCoordinatesAverage}
        r={2}
        fill={hasDatum ? SIFTED_BLUE : SIFTED_PINK}
      />
      <RadarLabels className="--radar-area-labels" labels={labels} />
    </svg>
  )
}

// *** POLYGON - GRID ***
interface GridProps {
  polarHaloes: number[][][]
}
const RadarGrid = ({ polarHaloes }: GridProps) => {
  const axesPoints = polarHaloes[polarHaloes.length - 1].map(
    (ph, i) => linePath([ph, polarHaloes[0][i]]) || ''
  )
  const haloesPathComplete = polarHaloes.reduce((acc, curr) => acc + linePath(curr) || '', '')

  return (
    <>
      {axesPoints.map((ap, i) => (
        <path key={i} d={ap} stroke={SIFTED_GRAY_STROKE} strokeWidth="0.5" />
      ))}
      <path
        d={haloesPathComplete}
        stroke={SIFTED_GRAY_STROKE}
        strokeWidth="0.5"
        fill="transparent"
      />
    </>
  )
}
interface RadarBubblesProps {
  bubbles: number[][]
  r: number
  className?: string
  fill?: string
}
const RadarBubbles = ({ bubbles, className = '', r = 0, fill = '' }: RadarBubblesProps) => (
  <g className={className}>
    {bubbles.map((bc, i) => {
      const [cx, cy] = bc
      return <circle key={i} cx={cx} cy={cy} r={r} fill={fill} />
    })}
  </g>
)

interface RadarAreaPathProps {
  path: string
  stroke?: string
  strokeDasharray?: string
  fill?: string
  className?: string
  strokeWidth?: number
}
const RadarAreaPath = ({
  path,
  stroke = 'none',
  strokeDasharray = 'none',
  fill = 'none',
  className = '',
  strokeWidth = 1,
}: RadarAreaPathProps) => (
  <g className={className}>
    <path
      d={path}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeDasharray={strokeDasharray}
    />
  </g>
)

interface RadarLabelsProps {
  labels: {
    text: string
    coordinates: number[]
    textOrientation: SvgPlacement
  }[]
  className?: string
}
const RadarLabels = ({ labels, className }: RadarLabelsProps) => (
  <g className={className}>
    {labels.map((label, i) => {
      const { text, coordinates, textOrientation } = label
      const [x, y] = coordinates
      return (
        <text key={i} x={x} y={y} {...textOrientation} className="text-xs font-bold">
          <tspan className="text-xs font-normal" fill={SIFTED_GRAY_DARK} {...textOrientation}>
            {`${ALFA_INDEX[i]}${text !== '' ? '.' : ''} `}
          </tspan>
          {text}
        </text>
      )
    })}
  </g>
)
