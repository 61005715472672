import { kebabCase } from 'lodash'
import React from 'react'
import { MapDevice, Region } from '../lib/constants'
import { getMapBorders } from '../lib/data-utils'

interface Props {
  activeRegion: Region | null
  mapDevice: MapDevice
  onMouseEnter?: (region: Region) => void
  onMouseLeave?: (region: Region) => void
  onClick?: (region: Region) => void
}

export const MapBordersInteractive = ({
  activeRegion,
  onClick = () => {},
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  mapDevice,
}: Props) => {
  // uncomment next line to allow click on region view
  if (activeRegion !== null) return null
  const borders = getMapBorders(activeRegion, mapDevice)

  return (
    <g>
      {borders.map((border, i) => (
        <path
          className={`--${kebabCase(border.region)} cursor-pointer`}
          key={i}
          d={border.d}
          onClick={() => onClick(border.region as Region)}
          onMouseEnter={() => onMouseEnter(border.region as Region)}
          onMouseLeave={() => onMouseLeave(border.region as Region)}
          fill="transparent"
        />
      ))}
    </g>
  )
}
