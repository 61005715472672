import React from 'react'
import { TalentAverage, TalentMax } from '../lib/data'
import { unsnake } from '../lib/format'
import { BigBubble } from './BigBubble'

export const TalentAndEmploymentHelp = () => (
  <div className="flex flex-wrap justify-between">
    {Object.entries(TalentAverage).map(([key, average]) => (
      <BigBubble
        key={key}
        dark
        showAverage
        max={TalentMax[key]}
        average={average}
        label={unsnake(key)}
        formattedValue={''}
        formattedAverage={String(Math.round(average))}
      />
    ))}
  </div>
)
