import React, { CSSProperties } from 'react'

interface Props {
  className?: string
  style?: CSSProperties
}

export const LogoSifted = (props: Props) => (
  <div {...props}>
    <a href="https://sifted.eu/" target="__blank">
      <img src="/sifted-logo.svg" alt="sifted-logo" />
    </a>
  </div>
)
