import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import { App } from './views/App'
import { StateProvider, state, history } from './state'
import './reset.css'
import './style.css'
import './lib/set-vh'

function renderApp() {
  ReactDOM.render(
    <StateProvider value={state}>
      <Router history={history}>
        <App />
      </Router>
    </StateProvider>,
    document.getElementById('root')
  )
}

// First render
renderApp()

// Hot module reloading
if (module.hot) {
  module.hot.accept('views/App', () => {
    console.clear()
    renderApp()
  })
}
