import { range } from 'lodash'

export const GRID_WIDTH = 1920
export const GRID_HEIGHT = 1200
export const GRID_DIAMETER = 33.4

const radius = GRID_DIAMETER / 2

const start = { x: 34.03, y: 21.98 }

// side of equilateral triangle where `radius` is the triangle height
const triangleSide = (2 * radius) / Math.sqrt(3)

const verticalDistance = triangleSide * 1.5
const cols = Math.ceil((GRID_WIDTH - start.x) / GRID_DIAMETER)
const rows = Math.ceil((GRID_HEIGHT - start.y) / verticalDistance)

export const GRID = range(-1, rows + 1)
  .flatMap((iy) =>
    range(-1, cols + (iy % 2 === 0 ? 1 : 0)).map((ix) => ({
      x: start.x + ix * GRID_DIAMETER + (iy % 2 === 0 ? 0 : radius),
      y: start.y + iy * verticalDistance,
    }))
  )
  .map((cell, index) => ({ ...cell, index }))

export const GRID_EXAGONS = GRID.map(
  (p) => `
    M${p.x} ${p.y - triangleSide} 
    L${p.x + radius} ${p.y - triangleSide / 2}
    L${p.x + radius} ${p.y + triangleSide / 2}
    L${p.x} ${p.y + triangleSide}
    L${p.x - radius} ${p.y + triangleSide / 2}
    L${p.x - radius} ${p.y - triangleSide / 2}
    Z
  `
)
