import { max } from 'lodash-es'
import React from 'react'
import { Region } from '../lib/constants'
import { Top5SectorsByRegion } from '../lib/data'
import { unsnake } from '../lib/format'
import { BigBubble } from './BigBubble'

interface Props {
  activeRegion: Region
}

export const Top5TechSectors = ({ activeRegion }: Props) => {
  const data = Top5SectorsByRegion[activeRegion]
  const maxCompanies = max(data.map((d) => d.companies)) ?? 0

  return (
    <div className="flex flex-wrap justify-between">
      {data.map((datum) => (
        <BigBubble
          key={datum.sector}
          value={datum.companies}
          max={maxCompanies}
          label={unsnake(datum.sector)}
        />
      ))}
    </div>
  )
}
