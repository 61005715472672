import React, { CSSProperties } from 'react'

interface Props {
  className?: string
  style?: CSSProperties
}

export const LogoMicrosoft = (props: Props) => (
  <div {...props}>
    <a href="https://startups.microsoft.com/" target="__blank">
      <img src="/mfs-logo.png" alt="msf-logo" />
    </a>
  </div>
)
