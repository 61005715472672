import React from 'react'

interface PillProps {
  className?: string
  textClass?: string
  text: string
  style?: React.CSSProperties
  leftIcon?: () => JSX.Element
  rightIcon?: () => JSX.Element
  onClick?: () => void
}

export const Pill = (props: PillProps) => {
  const {
    className = '',
    textClass = 'px-5',
    text,
    style,
    leftIcon: LeftIcon = () => null,
    rightIcon: RightIcon = () => null,
    onClick,
  } = props

  return (
    <div
      className={`${className} inline-flex items-center rounded-full p-2 cursor-pointer text-lg`}
      style={style}
      onClick={onClick}
    >
      <LeftIcon />
      <div className={textClass}>{text}</div>
      <RightIcon />
    </div>
  )
}
