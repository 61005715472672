import { capitalize } from 'lodash-es'
import React from 'react'
import { ALFA_INDEX } from '../lib/constants'
import { LIFE_QUALITY_FACTORS } from '../lib/data'
import { format, unsnake } from '../lib/format'

interface Props {
  className?: string
}

export const LifeQualityLegend = ({ className = '' }: Props) => (
  <div className={`flex flex-col flex-wrap mt-5 text-gray-sft-dark ${className}`}>
    {LIFE_QUALITY_FACTORS.map((f, i) => (
      <div key={i}>{`${ALFA_INDEX[i]}. ${format(f, capitalize, unsnake)}`}</div>
    ))}
  </div>
)
