import untypedLinks from '../datasets/Regional\ panel/Links.csv'

import { keyBy, keys, mapValues, range } from 'lodash-es'
import { checkMissingRegion } from './data-utils'
import { Source, strTuple } from './types'

const LINK_INDICATORS = strTuple(
  'Know_more',
  'VC_investors',
  'accelerators',
  'government_fundings',
  'universities',
  'workspaces'
)
type LinkIndicator = typeof LINK_INDICATORS[number]

export const LinkByRegion = mapValues(keyBy(untypedLinks, 'Region'), (datum: any) => {
  const indicators = LINK_INDICATORS.reduce((acc, indicator) => {
    acc[indicator] = {
      href: datum[`link_${indicator}`] as string,
      label: datum[`Indicator_${indicator}`] as string,
    }

    return acc
  }, {} as Record<LinkIndicator, Source>)

  const events = range(1, 10)
    .filter((i) => datum[`Event_${i}`])
    .map<Source>((i) => ({ href: datum[`link_Event_${i}`], label: datum[`Event_${i}`] }))

  const organisations = range(1, 10)
    .filter((i) => datum[`Organisation_${i}`])
    .map<Source>((i) => ({
      href: datum[`link_Organisation_${i}`],
      label: datum[`Organisation_${i}`],
    }))

  return { ...indicators, events, organisations }
})

// DATA CHECK
if (process.env.NODE_ENV === 'development') {
  checkMissingRegion('Salary Data', keys(LinkByRegion))
}
