import { observer } from 'mobx-react-lite'
import React, { memo } from 'react'
import { useWindowSize } from 'react-use'
import { GRID_HEIGHT, GRID_WIDTH } from '../lib/grid'
import { useMst } from '../state'
import { Canvas, useFrame } from './Canvas'
import { CanvasPrimitives } from './canvas-primitives'

interface MapLayerProps {
  children?: React.ReactNode
  type: 'canvas' | 'svg'
}

const CanvasScaleMap = ({ scale }: { scale: number }) => {
  useFrame(({ ctx, width, height }) => {
    const ratioX = width / GRID_WIDTH
    const ratioY = height / GRID_HEIGHT
    const ratio = Math.max(ratioX, ratioY)

    const scaledWidth = GRID_WIDTH * ratio
    const scaledHeight = GRID_HEIGHT * ratio

    ctx.translate((width - scaledWidth) / 2, (height - scaledHeight) / 2)
    ctx.scale(ratio, ratio)

    ctx.translate(GRID_WIDTH / 2, GRID_HEIGHT / 2)
    ctx.scale(scale, scale)
    ctx.translate(-GRID_WIDTH / 2, -GRID_HEIGHT / 2)
  })

  return null
}

export const MapLayer = observer(function MapLayerSvg({ children, type }: MapLayerProps) {
  const {
    device: { width, height },
    mapScaleFactor,
  } = useMst()

  // TODO: update scale factor on windowResize

  return (
    <>
      {type === 'svg' ? (
        <svg
          className="absolute top-0 left-0"
          width={width}
          height={height}
          viewBox={`0 0 ${GRID_WIDTH} ${GRID_HEIGHT}`}
          preserveAspectRatio="xMidYMid slice"
        >
          <g
            transform={`
            translate(${GRID_WIDTH / 2} ${GRID_HEIGHT / 2}) 
            scale(${mapScaleFactor}) 
            translate(${-GRID_WIDTH / 2} ${-GRID_HEIGHT / 2})`}
          >
            {children}
          </g>
        </svg>
      ) : (
        <Canvas className="absolute top-0 left-0" width={width} height={height}>
          <CanvasPrimitives.ClearRect />
          <CanvasPrimitives.Save />
          <CanvasScaleMap scale={mapScaleFactor} />
          {children}
          <CanvasPrimitives.Restore />
        </Canvas>
      )}
    </>
  )
})
