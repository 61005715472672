import { line, curveLinearClosed } from 'd3-shape'
import { radialLabelPlacement } from 'radial-label-placement'

export const linePath = line<number[]>()
  .curve(curveLinearClosed)
  .x((d) => d[0])
  .y((d) => d[1])

// Convert text placement from canvas to svg
type CanvasPlacement = {
  textAlign: 'end' | 'start' | 'center'
  textBaseline: 'middle' | 'top' | 'bottom'
}
export type SvgPlacement = {
  textAnchor: 'end' | 'start' | 'middle'
  alignmentBaseline: 'middle' | 'hanging' | 'baseline'
}
const conversionTextAlign = { start: 'start', center: 'middle', end: 'end' } as const
const conversionTextBaseline = { top: 'hanging', middle: 'middle', bottom: 'baseline' } as const

function fromCanvasPlacementToSvgPlacement({
  textAlign,
  textBaseline,
}: CanvasPlacement): SvgPlacement {
  return {
    textAnchor: conversionTextAlign[textAlign],
    alignmentBaseline: conversionTextBaseline[textBaseline],
  }
}

export function textRadialPlacement(angle: number) {
  return fromCanvasPlacementToSvgPlacement(radialLabelPlacement(angle))
}
