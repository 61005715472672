import React from 'react'

import { Texture, UKDataByRegion } from '../lib/data'
import { genGrid, getTextureIndex } from '../lib/data-utils'
import { MAP, MapDevice } from '../lib/constants'
import { Textures, TextureComponent, TexturesColors } from './texture-components'
import { get } from 'lodash-es'
import { GRID_DIAMETER } from '../lib/grid'

const genTextures = (texture: Texture, mapDevice: MapDevice) => {
  const grid = genGrid().map((cell) => ({
    ...cell,
    texture: null as TextureComponent | null,
  }))

  const components = Textures[texture]

  MAP[mapDevice].UK.regionsBubbles.map((region) => {
    const datum = get(UKDataByRegion, [region.name], null)
    if (!datum) return

    region.bubbles.forEach((index) => {
      grid[index].texture = components[getTextureIndex(datum[texture])]
    })
  })

  return grid
}

interface Props {
  texture: Texture
  mapDevice: MapDevice
}

export const MapTextures = ({ texture, mapDevice }: Props) => {
  const grid = genTextures(texture, mapDevice)
  const side = GRID_DIAMETER

  return (
    <g>
      {grid
        .filter((c) => c.texture !== null)
        .map((cell) => {
          const Texture = cell.texture!
          return (
            <Texture
              key={cell.index}
              x={cell.x - side / 2}
              y={cell.y - side / 2}
              width={side}
              height={side}
              color={TexturesColors[texture]}
            />
          )
        })}
    </g>
  )
}
