import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useMst } from '../state'
import { HomepageCardContent } from './HomepageCardContent'
import { Pill } from '../ui/Pill'
import { TEXT } from '../lib/constants'
import { ReactComponent as BubbleMap } from '../assets/svg/bubble-map.colors.svg'
import { ReactComponent as BubbleMapSmall } from '../assets/svg/bubble-map-mobile.colors.svg'
import { ReactComponent as ArrowRight } from '../assets/svg/arrow-right.svg'

interface HomepageCardMapPorps {
  className?: string
}

export const HomepageCardMap = observer(({ className = '' }: HomepageCardMapPorps) => {
  const { isLarge } = useMst().device
  const BackgroundMap = isLarge ? BubbleMap : BubbleMapSmall

  return (
    <HomepageCardContent
      className={`${className} lg:flex-grow`}
      title="Interactive map /"
      titleClass="lg:text-4xl text-px26 mb-4"
      description={TEXT.Homepage.IntroductionHomepage.Text}
      descriptionClass="w-3/4 mb-24  lg:mb-24 md:w-7/12"
    >
      <div className="flex">
        <Link to="/map">
          <Pill
            className="text-white bg-purplyBlue"
            text="Explore /"
            rightIcon={() => <ArrowRight width={35} />}
          />
        </Link>
      </div>
      <HomepageCardContent.Background>
        <div className="flex justify-end mx-auto h-full">
          <BackgroundMap
            className="rounded-sft w-full h-full"
            preserveAspectRatio="xMaxYMid meet"
          />
        </div>
      </HomepageCardContent.Background>
    </HomepageCardContent>
  )
})
