import React, { ComponentProps, ReactNode, useState } from 'react'
import { ReactComponent as InfoIcon } from '../assets/svg/info.colors.svg'
import { ReactComponent as CloseIcon } from '../assets/svg/close.colors.svg'
import { Card, CardProps } from '../ui/Card'

interface Props {
  children?: React.ReactNode
  sticky?: boolean
  help?: React.ReactNode
}

const Content = (props: ComponentProps<'div'>) => (
  <div
    {...props}
    className={`px-6 py-4 space-y-5  ${props.className ?? ''}`}
    style={{
      ...props.style,
    }}
  >
    {props.children}
  </div>
)

export const PanelCard = ({ children, sticky, help }: Props) => {
  const [showHelp, setShowHelp] = useState(false)

  const Icon = showHelp ? CloseIcon : InfoIcon
  return (
    <Card className={`overflow-hidden ${sticky ? 'sticky top-0 left-0 z-10' : 'relative'}`}>
      <Content className="bg-white">{children}</Content>

      {showHelp && (
        <Content className="absolute inset-0 text-white" style={{ backgroundColor: '#2C2C2C' }}>
          {help}
        </Content>
      )}

      {help && (
        <Icon
          className="absolute w-4 h-4 right-4 top-4 cursor-pointer"
          onClick={() => setShowHelp(!showHelp)}
        />
      )}
    </Card>
  )
}
