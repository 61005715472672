export type Component<T> = (props: T) => JSX.Element
export type HompageCardStateType = 'UNTOUCHED' | 'SELECTED' | 'NOTSELECTED'
export type MaybeArray<T> = T | T[]

export type Source = {
  href: string
  label: string
}

export const tuple = <T extends any[]>(...args: T) => args
export const strTuple = <T extends string[]>(...args: T) => args
