import React from 'react'
import { range } from 'lodash-es'
import { Link } from 'react-router-dom'
import { AboutCard, Title, Pragraph } from '../components/AboutCard'
import { Header } from '../components/Header'
import { LogoMicrosoft } from '../components/LogoMicrosoft'
import { MarkdownToReactComponent } from '../components/MarkdownToReactComponent'
import { Card } from '../ui/Card'
import { Pill } from '../ui/Pill'
import { TEXT } from '../lib/constants'
import { ReactComponent as SiftedLogo } from '../assets/svg/sifted-logo.colors.svg'
import { ReactComponent as ArrowRight } from '../assets/svg/arrow-right.svg'
import { ReactComponent as InvestmentSVG } from '../assets/svg/investment-tech-companies.colors.svg'
import { ReactComponent as StartupSVG } from '../assets/svg/startup-ecosystem.colors.svg'
import { ReactComponent as EmergingTechSVG } from '../assets/svg/emerging-tech-sectors.colors.svg'
import { ReactComponent as TooltipSVG } from '../assets/svg/tooltip.colors.svg'
import { ReactComponent as RegionalView } from '../assets/svg/regional-view.colors.svg'
import { ReactComponent as RegionalPanel } from '../assets/svg/regional-panel.colors.svg'
import { Footer } from '../components/Footer'

const ABOUT_CARDS = [
  {
    title: TEXT.AboutPage.HowToUseTheMap1.Title,
    paragraph: TEXT.AboutPage.HowToUseTheMap1.Text,
    background: InvestmentSVG,
    scaleBackgroundMobile: true,
    sources: [
      {
        label: 'Dealroom (2021)',
        href:
          'https://app.dealroom.co/transactions.rounds/f/growth_stages/not_mature/rounds/not_GRANT_SPAC%20PRIVATE%20PLACEMENT/slug_locations/anyof_~united_kingdom~/tags/not_outside%20tech?showStats=YEAR&statsType=rounds',
      },
    ],
  },
  {
    title: TEXT.AboutPage.HowToUseTheMap2.Title,
    paragraph: TEXT.AboutPage.HowToUseTheMap2.Text,
    background: StartupSVG,
    scaleBackgroundMobile: true,
    sources: [
      {
        label: 'Dealroom (2020)',
        href:
          'https://datacommons.technation.io/investors.accelerators/f/all_locations/allof_United%20Kingdom',
      },
      {
        label: 'gov.uk',
        href: 'https://www.gov.uk/check-university-award-degree/recognised-bodies',
      },
    ],
  },
  {
    title: TEXT.AboutPage.HowToUseTheMap3.Title,
    paragraph: TEXT.AboutPage.HowToUseTheMap3.Text,
    background: EmergingTechSVG,
    scaleBackgroundMobile: true,
    sources: [
      {
        label: 'Dealroom (2020)',
        href:
          'https://datacommons.technation.io/transactions.rounds/f/growth_stages/not_mature/industries/anyof_marketing/locations/allof_United%20Kingdom/regions/anyof_West%20Midlands/rounds/not_GRANT_SPAC%20PRIVATE%20PLACEMENT/tags/not_outside%20tech?sort=-amount',
      },
      { label: 'Tech Nation Report 2021', href: 'https://technation.io/report2021/#emerging-tech' },
    ],
  },
  {
    title: TEXT.AboutPage.HowToUseTheMap4.Title,
    paragraph: TEXT.AboutPage.HowToUseTheMap4.Text,
    background: TooltipSVG,
    scaleBackgroundMobile: true,
    sources: [
      {
        label: 'Dealroom (2020)',
        href:
          'https://datacommons.technation.io/transactions.rounds/f/growth_stages/not_mature/industries/anyof_marketing/locations/allof_United%20Kingdom/regions/anyof_West%20Midlands/rounds/not_GRANT_SPAC%20PRIVATE%20PLACEMENT/tags/not_outside%20tech?sort=-amount',
      },
    ],
  },
  {
    title: TEXT.AboutPage.HowToUseTheMap5.Title,
    paragraph: TEXT.AboutPage.HowToUseTheMap5.Text,
    background: RegionalView,
    scaleBackgroundMobile: true,
    sources: [
      {
        label: 'Dealroom (2020)',
        href:
          'https://datacommons.technation.io/transactions.rounds/f/growth_stages/not_mature/industries/anyof_robotics/locations/allof_United%20Kingdom/rounds/not_GRANT_SPAC%20PRIVATE%20PLACEMENT/slug_locations/anyof_london/tags/not_outside%20tech',
      },
    ],
  },
  {
    title: TEXT.AboutPage.HowToUseTheMap6.Title,
    paragraph: TEXT.AboutPage.HowToUseTheMap6.Text,
    background: RegionalPanel,
    scaleBackgroundMobile: false,
    sources: [
      {
        label: 'Dealroom (2020)',
        href: 'https://app.dealroom.co/companies.startups/f/slug_locations/anyof_~united_kingdom~',
      },
      {
        label: 'Tech Nation Report 2021',
        href: 'https://technation.io/report2021/#emerging-tech',
      },
      {
        label: 'gov.uk',
        href: 'https://www.gov.uk/check-university-award-degree/recognised-bodies',
      },
      {
        label: 'Numbeo',
        href:
          'https://docs.google.com/spreadsheets/d/1h1aO36w3T9KwU-EwHtgGls4paPGmPSvE5VTxVG7L-Xw/edit#gid=0',
      },
      {
        label: 'Diversity Beyond Gender - Extend Ventures Report (2020)',
        href: 'https://www.extend.vc/reports',
      },
    ],
  },
]

export const AboutPage = () => {
  return (
    <>
      <div className="lg:px-16 px-4 gray">
        <Header goToHomepage />
        <CardRow className="mb-16">
          <TopCard paragraph={TEXT.AboutPage.Project.Text}>
            <Title>{TEXT.AboutPage.Project.Title}</Title>
          </TopCard>
          <TopCard paragraph={TEXT.AboutPage.MicrosoftForStartups.Text}>
            <LogoMicrosoft className="w-32" />
          </TopCard>
          <TopCard paragraph={TEXT.AboutPage.Sifted.Text}>
            <SiftedLogo height={48} />
          </TopCard>
        </CardRow>

        <CardRow>
          <Title className="mb-9 lg:w-aboutpage-card">How to use the map</Title>
          <div className="lg:w-aboutpage-card"></div>
        </CardRow>

        <div className="mt-10">
          {range(ABOUT_CARDS.length / 2).map((i) => (
            <CardRow key={i} className="mb-7">
              {range(2).map((r) => {
                const index = r + 2 * i
                const {
                  title,
                  paragraph,
                  sources,
                  background,
                  scaleBackgroundMobile,
                } = ABOUT_CARDS[index]
                return (
                  <AboutCard
                    key={r}
                    title={title}
                    paragraph={paragraph}
                    sources={sources}
                    background={background}
                    scaleBackgroundMobile={scaleBackgroundMobile}
                  />
                )
              })}
            </CardRow>
          ))}
        </div>

        <div className="relative w-full flex flex-col items-center my-14">
          <Link to="/map">
            <Pill
              className="text-white bg-purplyBlue"
              text="Explore /"
              rightIcon={() => <ArrowRight width={35} />}
            />
          </Link>

          <div className="lg:absolute left-0 top-0 w-auto self-start pt-10 lg:pt-0">
            <BackToHome />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

interface TopCardProps {
  paragraph: string
  className?: string
}
const TopCard: React.FC<TopCardProps> = ({ children, className, paragraph }) => {
  return (
    <Card className={`${className} p-10 lg:max-w-aboutpage-card`}>
      <div className="mb-9 h-20">{children}</div>
      <Pragraph>
        <MarkdownToReactComponent text={paragraph} />
      </Pragraph>
    </Card>
  )
}
const CardRow: React.FC<{ className?: string }> = ({ children, className = '' }) => (
  <div
    className={`${className} flex lg:flex-row flex-col justify-center items-start lg:items-stretch lg:space-x-5 space-y-5 lg:space-y-0 md:w-full md:max-w-mobile-card md:mx-auto lg:max-w-none`}
  >
    {children}
  </div>
)

const BackToHome = () => (
  <div className="z-10">
    <Link to="/">
      <Pill
        text="Go back /"
        className="w-full text-purplyBlue px-0"
        leftIcon={() => <ArrowRight className="transform rotate-180" width={28} />}
      />
    </Link>
  </div>
)
