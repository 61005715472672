interface CounterOptions {
  onIncrement?: (count: number) => void
  onDecrement?: (count: number) => void
}

function counter(options: CounterOptions): [() => void, () => void] {
  const { onIncrement = () => {}, onDecrement = () => {} } = options

  let count = 0

  function increment() {
    count++
    onIncrement(count)
  }

  function decrement() {
    count--
    onDecrement(count)
  }

  return [increment, decrement]
}

type Callback = () => void

export function createAnimationLoop(draw: Callback): [Callback, Callback, Callback] {
  let animate = false

  function loop() {
    draw()
    if (animate) requestAnimationFrame(loop)
  }

  function startLoop() {
    animate = true
    loop()
  }

  function stopLoop() {
    animate = false
  }

  const [incrementAndStart, decrementAndStop] = counter({
    onIncrement: (count) => count === 1 && startLoop(),
    onDecrement: (count) => count === 0 && stopLoop(),
  })

  return [draw, incrementAndStart, decrementAndStop]
}
