import React, { useContext, useEffect, useRef } from 'react'
import { useConst } from '../hook/useConst'
import { scaleByDevicePixelRatio } from '../lib/canvas-utils'
import { useAnimationLoop } from '../hook/useAnimationLoop'

export interface FrameArgs {
  canvas: HTMLCanvasElement
  ctx: CanvasRenderingContext2D
  width: number
  height: number
}

export type FrameCallback = (args: FrameArgs) => void
export type RenderControlCallback = () => void

interface InternalContextValue {
  frames: FrameCallback[]
  play: RenderControlCallback
  stop: RenderControlCallback
}

const InternalContext = React.createContext<InternalContextValue | null>(null)

const useInternalContext = () => {
  const contextValue = useContext(InternalContext)
  if (!contextValue) throw new Error('Missing Canvas')
  return contextValue
}

interface Props {
  width: number
  height: number
  className?: string
  children?: React.ReactNode
  onDraw?: () => void
  onDrawStart?: () => void
  onDrawEnd?: () => void
}

export const Canvas = ({
  width,
  height,
  children,
  className = '',
  onDraw = () => {},
  onDrawStart = () => {},
  onDrawEnd = () => {},
}: Props) => {
  const ref = useRef<HTMLCanvasElement>(null)
  const frames = useConst<FrameCallback[]>([])

  frames.splice(0, frames.length)

  useEffect(() => {
    scaleByDevicePixelRatio(ref.current!, width, height)
  }, [width, height])

  const [draw, startLoop, stopLoop] = useAnimationLoop(() => {
    const canvas = ref.current
    if (!canvas) return

    const ctx = canvas.getContext('2d')!
    frames.forEach((frame) => frame({ canvas, ctx, width, height }))
    onDraw()
  }, [width, height, ref])

  useEffect(() => draw())

  const play = () => {
    startLoop()
    onDrawStart()
  }

  const stop = () => {
    stopLoop()
    onDrawEnd()
  }

  return (
    <InternalContext.Provider value={{ frames, play, stop }}>
      <canvas ref={ref} className={className}>
        {children}
      </canvas>
    </InternalContext.Provider>
  )
}

/**
 * Use this hook to tell the canvas what to draw every frame
 */
export const useFrame = (draw: FrameCallback) => useInternalContext().frames.push(draw)

/**
 * Use this hook to get play/stop function to tell the canvas
 * when to animate
 */
export const useRenderControls = () => {
  const { play, stop } = useInternalContext()
  return { play, stop }
}
