import { upperFirst, camelCase, first, tail, identity } from 'lodash-es'

// List of all available currencies https://www.techonthenet.com/js/currency_codes.php
export function formatPoundSteling(value: number) {
  return `£${value.toLocaleString('en-GB')}`
}

type Formatter = (text: string) => string
const buildFormatter = (...formatters: Formatter[]): Formatter => {
  const head = first(formatters)
  if (!head) return identity
  return (text: string) => buildFormatter(...tail(formatters))(head(text))
}

export const format = (text: string, ...formatters: ((text: string) => string)[]): string => {
  return buildFormatter(...formatters)(text)
}

export const pascalize = buildFormatter(camelCase, upperFirst)
export const unsnake = (text: string) => text.replaceAll('_', ' ')
