import MAPJson from '../map.json'
import TEXTJson from '../text.json'
import REGIONALTEXTJson from '../regional-text.json'
import { GRID_DIAMETER } from './grid'
import { tuple } from './types'

export const MAP = MAPJson
export const TEXT = TEXTJson
export const REGIONALTEXT = REGIONALTEXTJson
export const REGIONS = Object.keys(MAP.desktop.regions)
export const MAP_DEVICES = Object.keys(MAP)

export type Region = typeof REGIONS[number]
export type MapDevice = typeof MAP_DEVICES[number]

export const SIFTED_PINK = '#DF4B85'
export const SIFTED_PINK_TRANSPARENT = 'rgba(223,75,133,0.4)'
export const SIFTED_PINK_LIGHT = '#EAC1D1'
export const SIFTED_PINK_WHITE = '#FFE9E9'
export const SIFTED_BLUE = '#2C2664'
export const SIFTED_GRAY_STROKE = '#DCDCDC'
export const SIFTED_GRAY_DARK = '#8f8f8f'
export const MAP_BG_COLOR = '#e5e5e5'

export const BUBBLE_MAX_RADIUS = GRID_DIAMETER / 2 - 2

export interface BubbleStyle {
  fill?: string
  r?: number
}

export const BREAKPOINT = { LG: 1024 }
export const DEFAULT_BUBBLE_STYLE: Required<BubbleStyle> = {
  r: 0,
  fill: SIFTED_PINK_LIGHT,
}
export const ALFA_INDEX = 'ABCDEFGHIJKLMNOPQRSTUVWXZ'

export const RADAR_GRADIENT = tuple('#505050', '#96385D')
