import React from 'react'

export interface CardProps {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
  onClick?: () => void
}

export const Card = (props: CardProps) => {
  const { children, className = '', style = {}, onClick = () => {} } = props

  return (
    <div
      className={`${className} rounded-sft shadow-sft border-gray-sft`}
      style={style}
      onClick={onClick}
    >
      {children}
    </div>
  )
}
