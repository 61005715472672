import untypedUKData from '../datasets/UK\ Startup\ Map\ Data\ -\ Map\ Data.csv'
import untypedRegionData from '../datasets/UK\ Startup\ Map\ Data\ -\ Tech\ Sectors\ per\ region.csv'
import untypedCityData from '../datasets/Regional\ panel/Tech\ Sectors\ per\ cities.csv'
import untypedTop5TechSectors from '../datasets/Regional\ panel/Top\ 5\ Tech\ Sectors\ per\ region.csv'
import untypedTalent from '../datasets/Regional\ panel/Talent\ and\ employment\ -\ universities,\ makerspace,\ coworking.csv'
import untypedTalentSalary from '../datasets/Regional\ panel/Talent\ and\ employment\ -\ Salary.csv'

import untypedDEIEthnicity from '../datasets/Homepage\ card\ -\ DEI\ Metrics\ -\ ethnicity.csv'
import untypedDEIGender from '../datasets/Homepage\ card\ -\ DEI\ Metrics\ -\ gender.csv'
import untypedDEITeam from '../datasets/Homepage\ card\ -\ DEI\ Metrics\ -\ team.csv'
import untypedTechSectors from '../datasets/Homepage\ card\ -\ Tech\ sectors.csv'

import untypedInvestorsData from '../datasets/Regional\ panel/Investment\ -\ Angel\ &\ VC\ Investors.csv'
import untypedUnicorns from '../datasets/Regional\ panel/Investment\ -\ Unicorns.csv'
import untypedLifeQualityData from '../datasets/Regional panel/Life Quality.csv'
import untypedInvestmentData from '../datasets/Regional\ panel/Investment\ -\ Yearly\ Investment\ per\ tech\ sectors\ per\ region.csv'

import { strTuple } from './types'
import { checkMissingRegion } from './data-utils'
import {
  difference,
  uniq,
  keyBy,
  mapValues,
  groupBy,
  keys,
  fromPairs,
  range,
  meanBy,
  max,
  min,
} from 'lodash-es'
import { MAP, REGIONS } from './constants'

interface UKDatum {
  Region: string
  Universities: number
  Accelerators: number
  Coworking_Spaces: number
}

export const TEXTURES = strTuple('Universities', 'Coworking_Spaces', 'Accelerators')
export type Texture = typeof TEXTURES[number]

export const UKData = untypedUKData as UKDatum[]
export const UKDataByRegion = keyBy(UKData, (d) => d.Region)

export const SECTORS = strTuple(
  'Fintech',
  'Wellness',
  'Health',
  'Robotics',
  'Education',
  'Mobility',
  'Food',
  'Marketing',
  'Agritech',
  'Climate',
  'Real_Estate',
  'Energy'
)
export const LIFE_QUALITY_FACTORS = strTuple(
  'CO2_Emissions_per_km2',
  'Green_space_per_person',
  'Average_digital_tech_salary',
  'Monthly_Rent_in_the_city_center',
  'Monthly_transport_ticket',
  'Meal_for_two_people'
)

export type LifeQualityFactor = typeof LIFE_QUALITY_FACTORS[number]
export type Sector = typeof SECTORS[number]

export interface FactorDatum {
  value: number
  label: string
}

function groupData(data: any[], keys: string[]) {
  return data.map((datum) => {
    const sectorsEntries = keys.map((key) => {
      const value = datum[key]
      const label = datum[`${key}_to_display`]

      return [key, value === null ? null : { value, label }]
    })

    return { datum, groupedData: fromPairs(sectorsEntries) }
  })
}

export type RegionDatum = Record<Sector, FactorDatum | null> & {
  Name: string
  Total: FactorDatum
}

export type CityDatum = RegionDatum & {
  Region: string
}

export type LifeQualityDatum = Record<LifeQualityFactor, FactorDatum | null> & {
  Name: string
  Region: string
}

export const RegionData = groupData(untypedRegionData, SECTORS).map(({ datum, groupedData }) => {
  const Name = datum.Region

  const Total: FactorDatum = {
    value: datum.Total_region_value,
    label: datum.Total_region_value_to_display,
  }

  return { Name, Total, ...groupedData }
}) as RegionDatum[]

export const CityData = groupData(untypedCityData, SECTORS).map(({ datum, groupedData }) => {
  const Name = datum.City
  const Region = datum.Region

  const Total: FactorDatum = {
    value: datum.Total_city_value,
    label: datum.Total_city_to_display,
  }

  return { Region, Name, Total, ...groupedData }
}) as CityDatum[]

export const RegionDataByRegion = keyBy(RegionData, 'Name')
export const LifeQualityData = groupData(untypedLifeQualityData, LIFE_QUALITY_FACTORS).map(
  ({ datum, groupedData }) => {
    const Name = datum.City
    const Region = datum.Region

    return { Name, Region, ...groupedData }
  }
) as LifeQualityDatum[]
export const LifeQualityDataByRegion = keyBy(LifeQualityData, (d) => d.Region)

export const CityDataByRegion = groupBy(CityData, 'Region')
export const CityByRegion = mapValues(CityDataByRegion, (d) => d.map((c) => c.Name))

export const AvailableSectorsByRegion = mapValues(CityDataByRegion, (data) => {
  return SECTORS.filter((sector) => data.some((datum) => datum[sector] !== null))
})

export const DEIMetricsEthnicty = untypedDEIEthnicity as {
  Ethnicity: string
  Value: number
  Value_to_display: string
}[]

export const DEIMetricsGender = untypedDEIGender as {
  Gender: string
  Value: number
  Value_to_display: string
}[]

export const DEI_METRICS_TEAM_KEYS = strTuple('Seed', 'Early_stage', 'Later_stage')
export type DEIMetricsTeamKey = typeof DEI_METRICS_TEAM_KEYS[number]
export const DEIMetricsTeam = untypedDEITeam as (Record<DEIMetricsTeamKey, number> & {
  Team: 'All ethnic teams'
})[]

export const TechSectors = untypedTechSectors.flatMap(({ REGION, ...sectors }: any) => {
  return Object.entries(sectors).map(([sector, value]) => ({
    region: REGION as string,
    sector: sector as string,
    value: value as number,
  }))
})
export const InvestorsData = untypedInvestorsData.map((datum: any) => ({
  Region: datum.Region as string,
  data: ['VC_investors', 'Angel_investors'].map((key) => ({
    key,
    value: datum[`${key}_to_display`] as number,
  })),
}))
export const InvestorDataByRegion = keyBy(InvestorsData, (d) => d.Region)

export const Top5SectorsByRegion = mapValues(
  groupBy(untypedTop5TechSectors, 'REGION'),
  (data: any[]) =>
    data
      .map((datum) => ({
        sector: datum.SECTOR as string,
        companies: datum.COMPANIES as number,
      }))
      .sort((a, b) => b.companies - a.companies)
)

export const TalentByRegion = mapValues(
  keyBy(untypedTalent, 'Region'),
  (datum: Record<string, number>) =>
    Object.keys(datum)
      .filter((key) => key !== 'Region')
      .map((key) => ({ key, value: datum[key] }))
)

export const TalentAverage = mapValues(
  groupBy(Object.values(TalentByRegion).flat(), (d) => d.key),
  (data) => meanBy(data, (d) => d.value)
)
export const TalentMax = mapValues(
  groupBy(Object.values(TalentByRegion).flat(), (d) => d.key),
  (data) => max(data.map((d) => d.value)) ?? 0
)

export const TalentSalaryByRegion = mapValues(
  keyBy(untypedTalentSalary, 'Region'),
  (datum: any) => ({
    salary: datum.Average_digital_tech_salary as number,
    region: datum.Region as string,
    city: datum.City as string | null,
    data: [
      'Software_Developer',
      'Data_Scientist',
      'Front_End_Developer',
      'Full_Stack_Developer',
      'Infrastructure_Engineer',
      'Business_Analyst',
    ]
      .filter((key) => datum[key] !== null)
      .map((key) => ({ key, value: datum[key] as number })),
  })
)

export const TalentSalaryAverage = mapValues(
  groupBy(
    Object.values(TalentSalaryByRegion)
      .map((d) => d.data)
      .flat(),
    (d) => d.key
  ),
  (data) => meanBy(data, (d) => d.value)
)

export const TalentSalaryMax = mapValues(
  groupBy(
    Object.values(TalentSalaryByRegion)
      .map((d) => d.data)
      .flat(),
    (d) => d.key
  ),
  (data) => max(data.map((d) => d.value)) ?? 0
)

export const TalentSalaryMin = mapValues(
  groupBy(
    Object.values(TalentSalaryByRegion)
      .map((d) => d.data)
      .flat(),
    (d) => d.key
  ),
  (data) => min(data.map((d) => d.value)) ?? 0
)

export const UnicornsByRegion = keyBy(
  untypedUnicorns as {
    Region: string
    Unicorns: number
    Future_Unicorns: number
  }[],
  (d) => d.Region
)

export const InvestmentByRegion = mapValues(
  groupBy(untypedInvestmentData, 'Region'),
  (data: any[]) => {
    return data.flatMap((datum: any) =>
      range(2016, 2021).map((year) => ({
        year: String(year),
        value: datum[year] as number,
        sector: datum.Sector as string,
      }))
    )
  }
)
export const InvestmentMaxValue = Object.values(InvestmentByRegion)
  .flatMap((data) => data.map((d) => d.value))
  .sort((a, b) => b - a)[2]

// DATA CHECK
if (process.env.NODE_ENV === 'development') {
  checkMissingRegion('Map Data', uniq(UKData.map((d) => d.Region)))
  checkMissingRegion('Region Data', uniq(RegionData.map((d) => d.Name)))
  checkMissingRegion('City Data', uniq(CityData.map((d) => d.Region)))
  checkMissingRegion('Investor Data', keys(InvestorDataByRegion))
  checkMissingRegion('Investment Data', keys(InvestmentByRegion))
  checkMissingRegion('Top5Sectors Data', keys(Top5SectorsByRegion))
  checkMissingRegion('Talent Data', keys(TalentByRegion))
  checkMissingRegion('Salary Data', keys(TalentSalaryByRegion))
  checkMissingRegion('Unicorn Data', keys(UnicornsByRegion))

  REGIONS.forEach((region) => {
    const cityFromData = uniq(CityData.filter((c) => c.Region === region).map((c) => c.Name))
    const cityFromJSON = uniq(MAP.desktop.regions[region].citiesBubbles.map((c) => c.name))

    const missingCities = difference(cityFromData, cityFromJSON)
    if (missingCities.length) {
      console.error(
        `[City Data / ${region}]` +
          `\nMissing City: ${missingCities}` +
          `\nJSON Cities: ${cityFromJSON}`
      )
    }
  })
}
