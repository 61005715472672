import React from 'react'
import { max, maxBy, sumBy } from 'lodash-es'
import { observer } from 'mobx-react-lite'
import {
  DEIMetricsEthnicty,
  DEIMetricsGender,
  DEIMetricsTeam,
  DEI_METRICS_TEAM_KEYS,
} from '../lib/data'
import { Barchart } from './Barchart'
import { useMst } from '../state'

export const Title: React.FC = ({ children }) => <div className="font-bold text-lg">{children}</div>
export const Sub: React.FC = ({ children }) => <div className="font-bold text-sm">{children}</div>
export const TeamName: React.FC = ({ children }) => <div className="text-sm">{children}</div>

export const DEIMetrics = observer(() => {
  const { isLarge } = useMst().device
  const teamData = DEIMetricsTeam.map((datum) => {
    const data = DEI_METRICS_TEAM_KEYS.map((key) => ({ key, value: datum[key] }))
    const sum = sumBy(data, (d) => d.value)
    const team = datum.Team
    const barStyle = (maxSum: number) => ({ width: `${(sum / maxSum) * 100}%` })
    return { sum, data, team, barStyle }
  })

  const maxTeamSum = max(teamData.map((d) => d.sum)) ?? 0

  return (
    <div className="space-y-4">
      <Title>Access to VC funding by ethnicity and gender</Title>
      <Sub>Share of overall funding</Sub>
      <Barchart
        data={DEIMetricsEthnicty}
        getterValue={(v) => v.Value}
        formatValue={(v) => v.Value_to_display}
        getterLabel={(v) => v.Ethnicity}
      />

      <Sub>Access to VC funding by investment stage</Sub>

      {isLarge ? (
        <table className="w-full">
          <tbody>
            {teamData.map(({ data, team, barStyle }) => (
              <tr key={team}>
                <td className="flex">
                  <TeamName>{team}</TeamName>
                </td>
                <td className="py-1">
                  <Barchart
                    data={data}
                    barStyle={barStyle(maxTeamSum)}
                    getterValue={(v) => v.value}
                    formatValue={(v) => String(v.value)}
                    getterLabel={(v) => v.key.replace('_', ' ')}
                    labelsHorizontal
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <>
          {teamData.map(({ data, team, barStyle }) => (
            <React.Fragment key={team}>
              <TeamName>{team}</TeamName>
              <Barchart
                data={data}
                barStyle={barStyle(maxTeamSum)}
                getterValue={(v) => v.value}
                formatValue={(v) => String(v.value)}
                getterLabel={(v) => v.key.replace('_', ' ')}
                labelsHorizontal
              />
            </React.Fragment>
          ))}
        </>
      )}

      <Title>Gender</Title>
      <Sub>Share of investment by gender composition of team</Sub>
      <Barchart
        labelsHorizontal
        data={DEIMetricsGender}
        getterValue={(v) => v.Value}
        formatValue={(v) => v.Value_to_display}
        getterLabel={(v) => v.Gender}
      />
    </div>
  )
})
