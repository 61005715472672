import React, { useEffect, useRef, useState } from 'react'
import gsap from 'gsap'
import { extent } from 'd3-array'
import { scalePow } from 'd3-scale'
import { SIFTED_PINK, Region, MapDevice } from '../lib/constants'
import { getMapBorders } from '../lib/data-utils'
import { useConst } from '../hook/useConst'

const buildDurationScale = (pathLengths: number[]) => {
  const lengthsDomain = extent(pathLengths) as [number, number]
  const scale = scalePow().domain(lengthsDomain).range([0.5, 0.85])
  return scale
}

interface Props {
  activeRegion: Region | null
  hoveredRegion: Region | null
  mapDevice: MapDevice
}

export const MapBordersAnimated = ({ activeRegion, hoveredRegion, mapDevice }: Props) => {
  const bordersGroupRef = useRef<SVGGElement>(null)

  const paths = useConst<{ region: string; path: SVGPathElement }[]>([])

  const highlightedRegion = hoveredRegion ?? activeRegion
  const isNotHighlightingRegion = highlightedRegion !== null && highlightedRegion === activeRegion

  useEffect(() => {
    const group = bordersGroupRef.current
    if (!group) return

    const timeline = gsap.timeline()

    const prevPaths = paths.map(({ path }) => ({
      path,
      length: path.getTotalLength(),
    }))

    const prevDurationScale = buildDurationScale(prevPaths.map((p) => p.length))

    prevPaths.forEach(({ path, length }) => {
      const onComplete = () => {
        if (path.parentNode === group) {
          group.removeChild(path)
        }
      }
      const duration = prevDurationScale(length)
      timeline
        .to(
          path,
          {
            strokeDasharray: `0 ${length}`,
            strokeDashoffset: -length / 2,
            duration,
            ease: 'linear',
            onComplete,
          },
          0
        )
        .to(path, { fillOpacity: 0, duration: 0.5 }, 0)
    })

    const borders = getMapBorders(activeRegion, mapDevice)

    const newPaths = borders.map(({ region, d }) => {
      const path = document.createElementNS('http://www.w3.org/2000/svg', 'path')
      const isActiveRegion = region === activeRegion
      path.setAttribute('d', d)
      path.setAttribute('fill', '#fdfdfd')
      path.setAttribute('stroke', isActiveRegion ? SIFTED_PINK : '#848484')

      const length = path.getTotalLength()
      return { region, path, length }
    })

    const newDurationScale = buildDurationScale(newPaths.map((p) => p.length))
    newPaths.forEach(({ path, length, region }) => {
      group.appendChild(path)

      const duration = newDurationScale(length)

      timeline
        .fromTo(
          path,
          { strokeDasharray: `0 ${length}`, strokeDashoffset: -length / 2 },
          { strokeDasharray: `${length} 0`, strokeDashoffset: 0, duration, ease: 'linear' },
          0
        )
        .fromTo(
          path,
          { fillOpacity: 0 },
          { fillOpacity: region === activeRegion ? 1 : 0, duration: 0.5 },
          0.5
        )
    })

    paths.splice(0, paths.length)
    paths.push(...newPaths)
  }, [activeRegion, mapDevice])

  useEffect(() => {
    if (isNotHighlightingRegion) return

    const timeline = gsap.timeline()

    paths.forEach(({ path, region }) => {
      const fill = region === highlightedRegion ? '#fdfdfd' : 'transparent'
      const stroke = region === highlightedRegion ? SIFTED_PINK : '#848484'
      const fillOpacity = region === highlightedRegion ? 1 : 0
      timeline.to(path, { fill, stroke, fillOpacity, duration: 0.2 }, 0)
    })

    const group = bordersGroupRef.current
    const targetPath = paths.find(({ region }) => region === highlightedRegion)?.path

    if (!group || !targetPath) return

    group.removeChild(targetPath)
    group.appendChild(targetPath)
  }, [highlightedRegion, isNotHighlightingRegion])

  return <g ref={bordersGroupRef} />
}
