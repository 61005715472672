import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Map } from '../components/Map'
import { PanelLeft } from '../components/PaneLeft'
import { PanelRight } from '../components/PanelRight'
import { TooltipLegend } from '../components/TooltipLegend'
import { TooltipRegion } from '../components/TooltipRegion'
import { useMst } from '../state'
import { LogoMicrosoft } from '../components/LogoMicrosoft'
import { LogoSifted } from '../components/LogoSifted'

export const MapPage = observer(() => {
  const {
    device: { isLarge },
    map: { activeRegion },
  } = useMst()

  const [rightPanelClosed, setRightPanelClosed] = useState(true)

  return (
    <div className="w-full h-screen relative z-0">
      <Map />
      <div
        className={`p-3 absolute w-screen pointer-events-none
        ${isLarge ? 'bottom-0 right-0' : 'top-20 right-1'} `}
      >
        <div className="max-w-mobile-card lg:max-w-none w-full mx-auto ">
          <div
            className={`pointer-events-auto
              flex flex-col-reverse lg:flex-row items-end lg:items-center justify-end 
              space-y-reverse space-x-0 space-y-2 lg:space-x-4 lg:space-y-0`}
            style={{ transform: isLarge && activeRegion ? 'translateX(-450px)' : '' }}
          >
            <LogoSifted className="w-14 lg:w-36" />
            <LogoMicrosoft className="w-14 lg:w-28" />
          </div>
        </div>
      </div>

      {isLarge ? (
        <>
          <PanelLeft
            className={`absolute left-0 top-0 p-3 max-h-screen overflow-y-auto w-full`}
            style={{ maxWidth: 280 }}
          />
          <PanelRight
            stickyHead
            className="absolute right-0 top-0 max-h-screen overflow-y-auto"
            style={{ width: 450 }}
          />
        </>
      ) : (
        <div
          className={`
            absolute top-0 left-0 w-full max-h-screen 
            ${rightPanelClosed ? 'overflow-y-auto' : 'overflow-y-hidden'}`}
        >
          <div
            className={`
              sticky top-0 left-0 w-full z-30 overflow-y-auto py-3
              ${rightPanelClosed ? '' : 'bg-gray-sft-map h-screen'} 
            `}
          >
            <PanelLeft
              closed={rightPanelClosed}
              onCloseChange={setRightPanelClosed}
              className="w-full m-auto px-3 max-w-mobile-card"
            />
          </div>

          {activeRegion && <div style={{ height: `calc(var(--vh) * 100 - 180px)` }} />}

          <PanelRight className="max-h-full w-full m-auto max-w-mobile-card" />
        </div>
      )}

      <TooltipLegend />
      <TooltipRegion />
    </div>
  )
})
