import React from 'react'
import { observer } from 'mobx-react-lite'
import { ParentSize } from '@visx/responsive'
import { capitalize } from 'lodash-es'
import { useMst } from '../state'
import { Barchart } from './Barchart'
import { BubbleMatrix } from './BubbleMatrix'
import { PanelCard } from './PanelCard'
import {
  InvestmentByRegion,
  InvestmentMaxValue,
  InvestorDataByRegion,
  LifeQualityDataByRegion,
  TalentSalaryByRegion,
} from '../lib/data'
import { Top5TechSectors } from './Top5TechSectors'
import { TalentAndEmployment } from './TalentAndEmployment'
import { RADAR_GRADIENT, Region, REGIONS } from '../lib/constants'
import { TalentAndEmploymentSalary } from './TalentAndEmploymentSalary'
import { Select } from '../ui/Select'
import { LinkByRegion } from '../lib/links'
import { Link } from '../ui/Link'
import { Sources } from '../ui/Sources'
import { Unicorns } from './Unicorns'
import { VimeoVideo } from './VimeoVideo'
import { Radar } from './Radar'
import { LifeQualityLegend } from './LifeQualityLegend'
import { TEXT, REGIONALTEXT } from '../lib/constants'
import { format, formatPoundSteling, pascalize, unsnake } from '../lib/format'
import { TalentAndEmploymentSalaryHelp } from './TalentAndEmploymentSalaryHelp'
import { TalentAndEmploymentHelp } from './TalentAndEmploymentHelp'
import { MarkdownToReactComponent } from './MarkdownToReactComponent'
import { ValueDescription } from '../ui/ValueDescription'
import ReactMarkdown from 'react-markdown'

const Text: React.FC = ({ children }) => <div style={{ maxWidth: 300 }}>{children}</div>
const Title: React.FC = ({ children }) => (
  <Text>
    <div className="text-lg font-medium">{children}</div>
  </Text>
)
const SubTitle: React.FC = ({ children }) => (
  <Text>
    <div className="text-sm font-bold">{children}</div>
  </Text>
)
const Description: React.FC = ({ children }) => (
  <Text>
    <div className="text-xs text-gray-sft">{children}</div>
  </Text>
)
const Paragraph: React.FC = ({ children }) => (
  <Text>
    <div className="text-sm">{children}</div>
  </Text>
)
const ParagraphHelp: React.FC = ({ children }) => (
  <Text>
    <div className="text-sm text-gray-sft">{children}</div>
  </Text>
)
const CardAuthor: React.FC = ({ children }) => (
  <div className="text-sm font-bold" style={{ maxWidth: 300 }}>
    {children}
  </div>
)

const Bubble = (p: { diameter: number; label: string }) => (
  <div className="flex items-center">
    <div className="w-8">
      <div
        className="m-auto rounded-full bg-pink-sft"
        style={{ width: p.diameter, height: p.diameter }}
      />
    </div>
    <div>{p.label}</div>
  </div>
)

const AverageLine: React.FC = ({ children }) => (
  <div className="flex items-center space-x-3">
    <div className="border-t border-dashed border-pink-sft w-5" />
    <div className="text-sm">{children}</div>
  </div>
)

const Group: React.FC = ({ children }) => <div className="space-y-2">{children}</div>

interface Props {
  className?: string
  style?: React.CSSProperties
  stickyHead?: boolean
}

export const PanelRight = observer(({ className = '', style, stickyHead }: Props) => {
  const {
    map: { activeRegion, setActiveRegion },
  } = useMst()

  if (!activeRegion) return null

  const talentSalaryCity = TalentSalaryByRegion[activeRegion].city
  const links = LinkByRegion[activeRegion]
  const regionPrefix = ([
    'East England',
    'East Midlands',
    'North East',
    'North West',
    'South East',
    'South West',
    'West Midlands',
  ] as Region[]).includes(activeRegion)
    ? 'the'
    : ''
  const pascalizedRegion = pascalize(activeRegion)
  const correctActiveRegion = activeRegion === 'East England' ? 'East of England' : activeRegion

  return (
    <div className={`${className} space-y-2 p-3`} style={style}>
      <PanelCard sticky={stickyHead}>
        <Select value={activeRegion} options={REGIONS} onChange={setActiveRegion} />
      </PanelCard>

      <PanelCard>
        <Paragraph>
          <MarkdownToReactComponent text={REGIONALTEXT[pascalizedRegion].Introduction.Text} />
        </Paragraph>

        <Group>
          <SubTitle>{REGIONALTEXT[pascalizedRegion].NumberStartupsCapita.Title}</SubTitle>

          <div className="text-lg">
            <ReactMarkdown
              components={{
                strong: ({ node, ...props }) => (
                  <span className="font-bold text-pink-sft" {...props} />
                ),
              }}
            >
              {`**${REGIONALTEXT[pascalizedRegion].NumberStartupsCapita.Text}**
            ${REGIONALTEXT[pascalizedRegion].SentenceStartupsCapita.Text}`}
            </ReactMarkdown>
          </div>

          <Sources>
            <Sources.Item
              label="Dealroom (2021)"
              href="https://datacommons.technation.io/companies.startups/f/all_locations/allof_United%20Kingdom/geo/anyof_East%20Midlands?showGrid=false&showMap=true"
            />
            <Sources.Item
              label="'UK Population Estimates (gov.uk, June 2020)"
              href="https://www.ons.gov.uk/peoplepopulationandcommunity/populationandmigration/populationestimates/datasets/populationestimatesforukenglandandwalesscotlandandnorthernireland"
            />
          </Sources>
        </Group>
      </PanelCard>

      <PanelCard>
        <Title>{TEXT.RegionalPage.CardTechSectors.Title}</Title>
        <Paragraph>{TEXT.RegionalPage.CardTechSectors.Text}</Paragraph>

        <Group>
          <SubTitle>
            Top five tech sectors in {regionPrefix} {correctActiveRegion}
          </SubTitle>
          <Description>Number of active companies per sector</Description>
          <Top5TechSectors activeRegion={activeRegion} />
        </Group>

        <Sources>
          <Sources.Item
            label="Dealroom (2021)"
            href="https://datacommons.technation.io/heatmaps/funding/countries/united_kingdom/industries"
          />
        </Sources>
      </PanelCard>

      <PanelCard
        help={
          <>
            <Group>
              <Title>{TEXT.RegionalPage.CardInfoInvestment1.Title}</Title>
              <ParagraphHelp>{TEXT.RegionalPage.CardInfoInvestment1.Text}</ParagraphHelp>
            </Group>

            <div className="space-y-4">
              <SubTitle>Investment in £ per tech sector</SubTitle>
              <Bubble diameter={22} label="+ £100m" />
              <Bubble diameter={16} label="£50-100m" />
              <Bubble diameter={6} label="£1-50m" />
            </div>

            <div className="h-16" />
            <div className="space-y-4">
              <SubTitle>{TEXT.RegionalPage.CardInfoInvestment2.Title}</SubTitle>
              <ParagraphHelp>{TEXT.RegionalPage.CardInfoInvestment2.Text}</ParagraphHelp>
              <Barchart
                barStyle={{ height: 40 }}
                data={['VC investors', 'Angel investors']}
                getterValue={() => 0.5}
                formatValue={() => ''}
                getterLabel={(label) => label}
              />
            </div>
          </>
        }
      >
        <Title>{TEXT.RegionalPage.CardInvestment1.Title}</Title>

        <Paragraph>{TEXT.RegionalPage.CardInvestment1.Text}</Paragraph>

        <Group>
          <SubTitle>
            Yearly investment per tech sector in {regionPrefix} {activeRegion}
          </SubTitle>

          <ParentSize>
            {({ width }) => (
              <BubbleMatrix
                width={width}
                height={400}
                minValue={1000}
                maxValue={InvestmentMaxValue}
                data={InvestmentByRegion[activeRegion]}
                getterX={(datum) => datum.year}
                getterY={(datum) => datum.sector}
                getterRadius={(datum) => datum.value}
                formatY={(s) => format(s, capitalize, unsnake)}
              />
            )}
          </ParentSize>

          <Sources>
            <Sources.Item
              label="Dealroom (2021)"
              href="https://datacommons.technation.io/transactions.rounds/f/growth_stages/not_mature/locations/allof_United%20Kingdom/rounds/not_GRANT_SPAC%20PRIVATE%20PLACEMENT/tags/not_outside%20tech"
            />
          </Sources>
        </Group>

        <Group>
          <SubTitle>Investors</SubTitle>
          <Barchart
            data={InvestorDataByRegion[activeRegion].data}
            getterValue={(d) => d.value}
            formatValue={(d) => String(d.value)}
            getterLabel={(d) => format(d.key, unsnake)}
            barStyle={{ height: 35 }}
          />

          <Sources>
            <Sources.Item
              label="Dealroom (2021)"
              href="https://datacommons.technation.io/investors/f/all_locations/allof_United%20Kingdom"
            />
          </Sources>
        </Group>

        <div className="space-y-2" style={{ marginBottom: 40 }}>
          <Link sources={links.VC_investors} />
          <Link sources={links.government_fundings} />
        </div>

        <Group>
          <SubTitle>{TEXT.RegionalPage.CardInvestment2.Title}</SubTitle>
          <Paragraph>{TEXT.RegionalPage.CardInvestment2.Text}</Paragraph>
        </Group>

        <Unicorns activeRegion={activeRegion} />

        <Sources>
          <Sources.Item
            label="The Unicorn Club - Dealroom"
            href="https://datacommons.technation.io/unicorns/f/slug_locations/anyof_~united_kingdom~/tags/allof_verified%20unicorns%20and%20%241b%20exits"
          />
        </Sources>
      </PanelCard>

      <PanelCard>
        <Title>{REGIONALTEXT[pascalizedRegion].VideoPanels.Title}</Title>
        <Paragraph>
          <MarkdownToReactComponent text={REGIONALTEXT[pascalizedRegion].VideoPanels.Text} />
        </Paragraph>
        {REGIONALTEXT[pascalizedRegion].VideoPanelLink.Text && (
          <VimeoVideo src={REGIONALTEXT[pascalizedRegion].VideoPanelLink.Text} />
        )}
      </PanelCard>

      <PanelCard>
        <Paragraph>{REGIONALTEXT[pascalizedRegion].Quote.Text}</Paragraph>
        <CardAuthor>{REGIONALTEXT[pascalizedRegion].QuoteAuthor.Text}</CardAuthor>
      </PanelCard>

      <PanelCard
        help={
          <>
            <Group>
              <Title>{TEXT.RegionalPage.CardInfoLifeQuality.Title}</Title>
              <ParagraphHelp>{TEXT.RegionalPage.CardInfoLifeQuality.Text}</ParagraphHelp>
            </Group>
            <Group>
              <div className="flex justify-between items-start">
                <AverageLine>Average</AverageLine>

                <div className="w-44">
                  <div
                    className="w-full h-5"
                    style={{
                      background: `linear-gradient(to left, ${RADAR_GRADIENT[0]} 0%, ${RADAR_GRADIENT[1]} 100%)`,
                    }}
                  />
                  <div className="flex justify-between text-xs">
                    <div>Highest score</div>
                    <div>Lowest score</div>
                  </div>
                </div>
              </div>

              <ParentSize>
                {({ width }) => <Radar width={width} height={width * 0.75} />}
              </ParentSize>

              <LifeQualityLegend className="lg:h-12 text-xs" />
            </Group>
          </>
        }
      >
        <Group>
          <Title>{TEXT.RegionalPage.CardLifeQuality.Title}</Title>
          <Paragraph>{TEXT.RegionalPage.CardLifeQuality.Text}</Paragraph>
          <Description>
            The quality of life data is based on {LifeQualityDataByRegion[activeRegion].Name}.
          </Description>
        </Group>
        <Group>
          <ParentSize>
            {({ width }) => (
              <Radar
                width={width}
                height={width * 0.8}
                lifeQualityRegion={LifeQualityDataByRegion[activeRegion]}
              />
            )}
          </ParentSize>

          <LifeQualityLegend className="lg:h-12 text-xs" />
        </Group>

        <Sources>
          <Sources.Item
            label="UK local authority and regional carbon dioxide emissions national statistics (2018)"
            href="https://www.gov.uk/government/statistics/uk-local-authority-and-regional-carbon-dioxide-emissions-national-statistics-2005-to-2018"
          />
          <Sources.Item
            label="Fields in Trust Green Space Index (2020)"
            href="http://www.fieldsintrust.org/green-space-index"
          />
          <Sources.Item
            label="Technation Median dig tech salary (2019)"
            href="https://technation.io/report2021/"
          />
          <Sources.Item
            label="Numbeo Statistics (2020)"
            href="https://www.numbeo.com/cost-of-living/"
          />
        </Sources>
      </PanelCard>

      <PanelCard
        help={
          <>
            <Group>
              <Title>{TEXT.RegionalPage.CardInfoTalentAndEmployment1.Title}</Title>
              <ParagraphHelp>{TEXT.RegionalPage.CardInfoTalentAndEmployment1.Text}</ParagraphHelp>
            </Group>

            <div className="h-8" />

            <div className="space-y-3">
              <SubTitle>Talent and employment</SubTitle>
              <AverageLine>Average value</AverageLine>
              <TalentAndEmploymentHelp />
            </div>

            <div className="h-16" />

            <div className="space-y-3">
              <Title>{TEXT.RegionalPage.CardInfoTalentAndEmployment2.Title}</Title>
              <ParagraphHelp>{TEXT.RegionalPage.CardInfoTalentAndEmployment2.Text}</ParagraphHelp>

              <TalentAndEmploymentSalaryHelp />
            </div>
          </>
        }
      >
        <Title>{TEXT.RegionalPage.CardTalentAndEmployment1.Title}</Title>
        <Paragraph>{TEXT.RegionalPage.CardTalentAndEmployment1.Text}</Paragraph>
        <TalentAndEmployment activeRegion={activeRegion} />

        <Sources>
          <Sources.Item
            label="Gov.uk"
            href="https://www.gov.uk/check-university-award-degree/recognised-bodies"
          />
          <Sources.Item
            label="nesta.org.uk"
            href="https://www.nesta.org.uk/archive-pages/uk-makerspaces-the-data/"
          />
          <Sources.Item
            label="Unesco"
            href="http://www.unesco.org/new/en/natural-sciences/science-technology/university-industry-partnerships/science-parks-around-the-world/science-parks-in-europe/"
          />
          <Sources.Item
            label="commonwealthofnations.org"
            href="https://www.commonwealthofnations.org/sectors-united_kingdom/education/research_institutes/"
          />
        </Sources>

        <div className="space-y-2" style={{ marginBottom: 40 }}>
          <Link sources={links.universities} />
          <Link sources={links.accelerators} />
          <Link sources={links.workspaces} />
        </div>

        <Group>
          <SubTitle>{TEXT.RegionalPage.CardTalentAndEmployment2.Title}</SubTitle>
          <Paragraph>{TEXT.RegionalPage.CardTalentAndEmployment2.Text}</Paragraph>
        </Group>

        <ValueDescription
          value={formatPoundSteling(TalentSalaryByRegion[activeRegion].salary)}
          description="Average digital tech salary"
        />

        <Group>
          {talentSalaryCity ? (
            <SubTitle>Salary by tech role for {talentSalaryCity} (2019)</SubTitle>
          ) : (
            <>
              <SubTitle>Salary by tech role</SubTitle>
              <Paragraph>(No salary data available for {activeRegion})</Paragraph>
            </>
          )}
          <TalentAndEmploymentSalary activeRegion={activeRegion} />
        </Group>

        <Sources>
          <Sources.Item label="Tech Nation Report 2020" href="https://technation.io/report2021/" />
        </Sources>
      </PanelCard>

      <PanelCard>
        <Title>{TEXT.RegionalPage.CardInitiatives.Title}</Title>
        <Paragraph>{TEXT.RegionalPage.CardInitiatives.Text}</Paragraph>

        <SubTitle>Events</SubTitle>
        <Group>
          {links.events.map((source, i) => (
            <Link key={i} sources={source} />
          ))}
        </Group>

        <SubTitle>Organisations</SubTitle>
        <Group>
          {links.organisations.map((source, i) => (
            <Link key={i} sources={source} />
          ))}

          <Link
            sources={{
              label: 'Microsoft for Startups',
              href: 'https://startups.microsoft.com/',
            }}
          />
          <Paragraph>
            <a href="https://startups.microsoft.com/" target="_blank" className="hover:underline">
              Apply to the Microsoft for Startups programme now.
            </a>
          </Paragraph>
        </Group>
      </PanelCard>
      <PanelCard>
        <Link big sources={links.Know_more} />
      </PanelCard>
    </div>
  )
})
