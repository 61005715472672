import React from 'react'
import { hsl } from 'd3-color'
import { capitalize } from 'lodash-es'
import { observer } from 'mobx-react-lite'
import { SECTORS } from '../lib/data'
import { useMst } from '../state'

export const SelectSector = observer(function PanelSectorSelect() {
  const { isSectorActive, isSectorAvailable, onSectorClick, sectorColors } = useMst().map

  return (
    <div className="flex flex-wrap">
      {SECTORS.map((sector) => {
        const isActive = isSectorActive(sector)
        const isClickable = isSectorAvailable(sector)
        const backgroundColor = sectorColors[sector] ?? 'white'
        const color = isActive ? (hsl(backgroundColor).l > 0.4 ? '#000' : '#fff') : '#AEAEAE'
        const borderColor = isActive ? backgroundColor : '#AEAEAE'
        const opacity = isClickable ? 1 : 0.33
        return (
          <div
            className={`border rounded-full px-3 py-1 m-1 text-sm transition flex select-none
            ${isClickable ? 'cursor-pointer' : 'cursor-not-allowed'}`}
            style={{ backgroundColor, color, borderColor, opacity }}
            key={sector}
            onClick={() => isClickable && onSectorClick(sector)}
          >
            <div>{capitalize(sector).replace('_', ' ')}</div>
            <div className={`pl-2 ${isActive ? '' : 'hidden'}`}>&times;</div>
          </div>
        )
      })}
    </div>
  )
})
