import * as canova from 'canova'
import { DEFAULT_BUBBLE_STYLE } from '../lib/constants'
import { GRID } from '../lib/grid'
import { useFrame } from './Canvas'

export const MapGrid = () => {
  useFrame(({ ctx }) => {
    canova.draw(
      ctx,
      GRID.map((circle) => canova.circle(circle.x, circle.y, 1, DEFAULT_BUBBLE_STYLE))
    )
  })
  return null
}
