import React from 'react'
import { observer } from 'mobx-react-lite'
import { ReactComponent as CitiesIcon } from '../assets/svg/cities-icon.svg'
import { useMst } from '../state'
import { Region, SIFTED_PINK } from '../lib/constants'
import { CityByRegion, RegionDataByRegion, SECTORS, UKDataByRegion } from '../lib/data'
import { Textures, TexturesColors } from './texture-components'
import { getTextureIndex } from '../lib/data-utils'
import { capitalize, round, sum } from 'lodash-es'
import { CardTooltip } from '../ui/CardTooltip'
import { Pill } from '../ui/Pill'
import { ReactComponent as ArrowRight } from '../assets/svg/arrow-right.svg'
import { format, unsnake } from '../lib/format'

export const TooltipRegion = observer(() => {
  const { isLarge } = useMst().device
  const { hoveredRegion, resetHoveredRegion, setActiveRegion } = useMst().map

  if (!hoveredRegion) return null
  return (
    <div
      className={`absolute p-3 lg:w-64 w-auto
      ${isLarge ? 'top-0 right-0' : 'right-0 left-0 bottom-0'}`}
    >
      <div className="w-full m-auto max-w-mobile-card">
        <CardTooltip
          className="w-full"
          title={hoveredRegion}
          closable={!isLarge}
          onCloseClick={resetHoveredRegion}
        >
          <div className="flex flex-col space-x-4">
            <div className="text-xs lg:text-sm space-y-4 flex-auto">
              <TooltipRegionContent hoveredRegion={hoveredRegion} />
            </div>
            {!isLarge && (
              <div className="self-center">
                <Pill
                  onClick={() => setActiveRegion(hoveredRegion)}
                  text="Explore /"
                  style={{ minWidth: 140 }}
                  className="w-full text-purplyBlue px-0"
                  rightIcon={() => <ArrowRight className="transform" width={28} />}
                />
              </div>
            )}
          </div>
        </CardTooltip>
      </div>
    </div>
  )
})

const Section = (props: { title: string; icon?: React.ReactNode; label: string }) => {
  return (
    <div className="space-y-1">
      <div className="font-medium">{props.title}</div>
      <div className="flex items-center space-x-1">
        {props.icon && <div className="flex justify-start">{props.icon}</div>}
        <div>{props.label}</div>
      </div>
    </div>
  )
}

const BubbleIcon = (props: { color: string }) => (
  <div className="w-4 h-4 rounded-full" style={{ backgroundColor: props.color }} />
)

interface Props {
  hoveredRegion: Region
}
const TooltipRegionContent = observer(({ hoveredRegion }: Props) => {
  const {
    map: { sectorColors, activeSectors, activeTexture },
  } = useMst()

  const cities = CityByRegion[hoveredRegion]
  const datum = UKDataByRegion[hoveredRegion]
  const sectorDatum = RegionDataByRegion[hoveredRegion]
  const totalInvestment = sectorDatum.Total
  const sectorInvestmentPerc =
    (sum(SECTORS.map((s) => sectorDatum[s]?.value)) / totalInvestment.value) * 100

  const sectorSections = activeSectors.map((sector) => ({
    sector,
    color: sectorColors[sector]!,
    label: sectorDatum[sector]?.label,
  }))

  const texture = !activeTexture
    ? null
    : {
        label: String(datum[activeTexture]),
        icon: Textures[activeTexture][getTextureIndex(datum[activeTexture])],
        color: TexturesColors[activeTexture],
      }

  return (
    <>
      <Section
        title="Startup hubs"
        label={cities.join(', ')}
        icon={<CitiesIcon width={16} height={16} color="#8F8F8F" />}
      />

      <div className="w-full border-t" />

      <Section
        title="Total investment in 2020"
        label={totalInvestment.label}
        icon={<BubbleIcon color={SIFTED_PINK} />}
      />

      {sectorSections.length > 0 && (
        <div className="space-y-2">
          <Section
            title="Investment in tech sectors"
            label={`${round(sectorInvestmentPerc, 1)} %`}
          />
          <div
            className="rounded-full h-2 relative overflow-hidden"
            style={{ backgroundColor: '#dfdfe0' }}
          >
            <div
              className="absolute top-0 left-0 h-full"
              style={{ width: `${sectorInvestmentPerc}%`, backgroundColor: '#8F8F8F' }}
            />
          </div>
        </div>
      )}

      <div className="flex flex-wrap  lg:flex-col -mx-3 lg:space-y-3">
        {sectorSections.map(({ label, sector, color }) => (
          <div key={sector} className="mx-3 ">
            <Section
              title={format(sector, capitalize, unsnake)}
              label={label ?? 'No data'}
              icon={<BubbleIcon color={color} />}
            />
          </div>
        ))}
      </div>

      {texture && (
        <Section
          title={activeTexture!}
          label={texture.label}
          icon={<texture.icon width={16} height={16} color={texture.color} />}
        />
      )}
    </>
  )
})
