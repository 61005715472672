import React from 'react'
import { sum } from 'lodash'
import { SIFTED_PINK } from '../lib/constants'

interface Props<Datum> {
  barStyle?: React.CSSProperties
  labelsHorizontal?: boolean
  data: Datum[]
  getterValue: (value: Datum) => number
  formatValue: (value: Datum) => string
  getterLabel: (value: Datum) => string
}

export function Barchart<Datum>({
  data,
  getterValue,
  formatValue,
  getterLabel,
  barStyle,
  labelsHorizontal,
}: Props<Datum>) {
  const values = data.map(getterValue)
  const labels = data.map((v) => ({ formatted: formatValue(v), label: getterLabel(v) }))

  const total = sum(values)
  const percentages = values.map((v) => (v / total) * 100)

  return (
    <div className="space-y-1">
      <div className="flex overflow-hidden w-100 bg-white h-4 rounded-full w-100" style={barStyle}>
        {percentages.map((perc, i) => (
          <div
            key={i}
            style={{
              width: `${perc}%`,
              opacity: 1 - i / percentages.length,
              backgroundColor: SIFTED_PINK,
            }}
          />
        ))}
      </div>

      <div className="flex flex-wrap justify-between -m-1">
        {labels.map((label, i) => (
          <div
            key={i}
            className={`m-1 ${
              labelsHorizontal
                ? 'flex space-x-1'
                : i === labels.length - 1
                ? 'flex flex-col items-end'
                : ''
            }
            `}
          >
            <div className="font-bold text-sm">{label.formatted}</div>
            <div className="text-sm" style={{ color: '#8F8F8F' }}>
              {label.label}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
