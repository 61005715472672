import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { MapPage } from '../views/MapPage'
import { AboutPage } from '../views/AboutPage'
import { Homepage } from './Homepage'

export const Routes = () => (
  <Switch>
    <Route exact path="/" component={Homepage} />
    <Route path="/map" component={MapPage} />
    <Route path="/about" component={AboutPage} />

    <Route>
      <Redirect to="/" />
    </Route>
  </Switch>
)
