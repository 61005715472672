import React from 'react'
import { Region } from '../lib/constants'
import { TalentAverage, TalentByRegion, TalentMax } from '../lib/data'
import { unsnake } from '../lib/format'
import { BigBubble } from './BigBubble'

interface Props {
  activeRegion: Region
}

export const TalentAndEmployment = ({ activeRegion }: Props) => {
  const data = TalentByRegion[activeRegion]

  const richData = data.map(({ key, value }) => {
    return {
      value,
      max: TalentMax[key],
      average: TalentAverage[key],
      label: unsnake(key),
    }
  })

  return (
    <div className="flex flex-wrap justify-between">
      {richData.map((datum) => (
        <BigBubble key={datum.label} {...datum} />
      ))}
    </div>
  )
}
