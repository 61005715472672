import React from 'react'

interface Props<Option extends string> {
  options: Option[]
  value: Option
  onChange?: (value: Option) => void
}

export function Select<Option extends string>({
  options,
  value,
  onChange = () => {},
}: Props<Option>) {
  return (
    <div className="relative">
      <div className="text-3xl font-bold pointer-events-none w-full flex justify-between items-center">
        <div>{value}</div>
        <svg className="mr-2" width="13" height="8" viewBox="0 0 13 8">
          <path d="M1 1L6.5 6L12 1" fill="none" stroke="#3A3A3A" strokeWidth="2" />
        </svg>
      </div>
      <div className="absolute inset-0">
        <select
          className="opacity-0 w-full h-full"
          value={value}
          onChange={(e) => onChange(e.target.value as Option)}
        >
          {options.map((o) => (
            <option value={o} key={o}>
              {o}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}
