import React from 'react'
import { Link } from 'react-router-dom'
import { Navigation } from '@siftedeu/sifted-shared-components'
import { Header } from '../components/Header'
import { HomepageCardMap } from '../components/HomepageCardMap'
import { HomepageCardContent } from '../components/HomepageCardContent'
import { HomepageOverviewCards } from '../components/HomepageOverviewCards'
import { Pill } from '../ui/Pill'
import { ReactComponent as ArrowRight } from '../assets/svg/arrow-right.svg'
import { ReactComponent as BubblesBlue } from '../assets/svg/bubbles-blue.colors.svg'
import { ReactComponent as BubblesBlueMobile } from '../assets/svg/bubbles-blue-mobile.colors.svg'
import { Footer } from '../components/Footer'

export const Homepage = () => {
  const responsiveClass = 'md:w-full md:max-w-mobile-card md:mx-auto lg:max-w-none'
  return (
    <>
      <div className="lg:px-16 px-4 gray">
        <Header />
        <div className="lg:flex lg:space-x-5 mb-6">
          <HomepageCardMap className={responsiveClass} />
          <HomepageCardContent
            className={`${responsiveClass} mt-5 lg:mt-0 h-72 lg:h-auto lg:w-1/4 w-full`}
            title="About the project /"
            titleClass="lg:w-48 xl:w-full w-1/2"
          >
            <HomepageCardContent.Background>
              <BubblesBlue
                className="hidden lg:block w-full h-full"
                preserveAspectRatio="xMaxYMid meet"
              />
              <BubblesBlueMobile
                className="block lg:hidden w-full h-full"
                preserveAspectRatio="xMaxYMid meet"
              />
            </HomepageCardContent.Background>
            <Link className="absolute bottom-4" to="/about">
              <Pill
                text="Learn more /"
                textClass="pr-5"
                className="w-full text-purplyBlue px-0"
                rightIcon={() => <ArrowRight width={28} />}
              />
            </Link>
          </HomepageCardContent>
        </div>
        <div className={`${responsiveClass} xl:flex xl:space-x-5 mb-28 xl:space-y-0 space-y-5`}>
          <HomepageOverviewCards />
        </div>
      </div>
      <Footer />
    </>
  )
}
