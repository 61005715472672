import { useContext, createContext } from 'react'
import { types as t, Instance } from 'mobx-state-tree'
import { syncHistoryWithStore, RouterModel } from 'mst-react-router'
import { createBrowserHistory } from 'history'
import { MapModel } from './map'
import { DeviceModel } from './device'
import { MapDevice } from '../lib/constants'

export const router = RouterModel.create()
export const history = syncHistoryWithStore(createBrowserHistory(), router)

export const StateModel = t
  .model('StateModel', {
    router: RouterModel,
    map: t.optional(MapModel, {}),
    device: t.optional(DeviceModel, {}),
  })
  .views((self) => ({
    get mapScaleFactor() {
      return self.device.width > 768 ? 1.25 : 1
    },
  }))
  .views((self) => ({
    get mapDevice(): MapDevice {
      return self.device.isLarge ? 'desktop' : 'mobile'
    },
  }))

export const state = StateModel.create({ router })

if (process.env.NODE_ENV === 'development') {
  window['GLOBALSTATE'] = state
}
interface StateType extends Instance<typeof StateModel> {}

const StateContext = createContext<StateType | null>(null)
export const StateProvider = StateContext.Provider

export function useMst() {
  const context = useContext(StateContext)
  if (!context) throw new Error('StateProvider is missing')

  return context
}
