import React from 'react'
import { line } from 'd3'
import { SIFTED_PINK } from '../lib/constants'

const HEIGHT = 40

interface Props {
  width: number
  minValue: string
  maxValue: string
  minDiameter: number
  maxDiameter: number
}

export const LegendInvestment = ({
  width,
  minValue,
  maxValue,
  minDiameter,
  maxDiameter,
}: Props) => {
  return (
    <svg
      width={width}
      height={HEIGHT}
      viewBox={`0 0 ${width} ${HEIGHT}`}
      className="overflow-visible"
    >
      <path
        d={
          line<[number, number]>()
            .x((p) => p[0])
            .y((p) => p[1])([
            [minDiameter / 2, maxDiameter],
            [minDiameter / 2, maxDiameter - minDiameter],
            [width - maxDiameter / 2, 0],
            [width - maxDiameter / 2, maxDiameter],
          ])!
        }
        fill={'#FFE9E9'}
      />

      <circle
        cx={minDiameter / 2}
        cy={maxDiameter - minDiameter / 2}
        r={minDiameter / 2}
        fill={SIFTED_PINK}
      />

      <circle
        cx={width - maxDiameter / 2}
        cy={maxDiameter / 2}
        r={maxDiameter / 2}
        fill={SIFTED_PINK}
      />

      <text className="text-xs" x={0} y={maxDiameter + 5} dominantBaseline="hanging" fill="#8F8F8F">
        {minValue}
      </text>
      <text
        className="text-xs"
        x={width - maxDiameter}
        y={maxDiameter + 5}
        dominantBaseline="hanging"
        fill="#8F8F8F"
      >
        {maxValue}
      </text>
    </svg>
  )
}
